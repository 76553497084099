import {
  fetchGndpPtsList,
  fetchGndpPtsDistinctList,
  fetchDtaGndpAcCnt,
  fetchBbzdtInf,
} from '../../../lib/fxp/GndpPts.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import dayjs from 'dayjs';
import { fetchCommonComboList } from '../../../lib/cmm/combo.api';

// actions
const FETCH_GNDP_PTS_LIST = 'GndpPts/FETCH_GNDP_PTS_LIST';
const FETCH_GNDP_PTS_DIS_LIST = 'GndpPts/FETCH_GNDP_PTS_DIS_LIST';
const FETCH_DTA_GNDP_AC_CNT = 'GndpPts/FETCH_DTA_GNDP_AC_CNT';

const FETCH_IST_INFO_LIST = 'GndpPts/FETCH_IST_INFO_LIST';
const FETCH_DEPT_INFO_LIST = 'GndpPts/FETCH_DEPT_INFO_LIST';
const FETCH_ACT_NO_LIST = 'GndpPts/FETCH_ACT_NO_LIST';
const FETCH_BIZ_NO_LIST = 'GndpPts/FETCH_BIZ_NO_LIST';
const FETCH_BBZDT_INF = 'GndpPts/FETCH_BBZDT_INF';

const RESET_INITIAL_STATE = 'GndpPts/RESET_INITIAL_STATE';
const SAVE_SEARCH_FORM_VALUES = 'GndpPts/SAVE_SEARCH_FORM_VALUES';

const CLEAR_MESSAGE = 'GndpPts/CLEAR_MESSAGE';
const SET_BEFORE_SEARCH_PARAM = 'GndpPts/SET_BEFORE_SEARCH_PARAM';

export const initFormValues = {
  cus_ist_cd: '6110000',
  cus_dept_cd: 'all',
  ZERO_YN: 'all',
  ydcs8a1_t8a12_act_no: 'all',
  biz_no: 'all',
  CPCAD_YN: 'all',
  APV_DIS: '1',
  inq_dt: dayjs().add(-1, 'day').format('YYYY.MM.DD'),
  SECHUL_YN: 'all',
  user_tp_cd: '',
  pmdp_act_dscd: '02',
  ydcs8a1_t8a01_ser_no: '00000000',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  dtaGndpAcCnt: [],
  totalCount: 0,
  beforeSearchParam: {},
  inqDtSuccess: false,
  inqDtLoading: false,
  inqDt: dayjs().format('YYYYMMDD'),

  //콤보 - 기관명 922
  istInfoSuccess: false,
  istInfoLoading: false,
  istInfoList: [],

  //콤보 - 부서명 174
  deptInfoSuccess: false,
  deptInfoLoading: false,
  deptInfoList: [],

  //콤보 - 계좌번호 206
  actNoSuccess: false,
  actNoLoading: false,
  actNoList: [],

  //콤보 - 사업자번호 044
  bizNoSuccess: false,
  bizNoLoading: false,
  bizNoList: [],

  // 검색조건

  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function GndpPts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_GNDP_PTS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.totalCnt,
      };
    case REQUEST(FETCH_GNDP_PTS_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_GNDP_PTS_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_GNDP_PTS_DIS_LIST): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.totalCnt,
      };
    case REQUEST(FETCH_GNDP_PTS_DIS_LIST): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_GNDP_PTS_DIS_LIST): // 조회실패
      return {
        ...state,
        list: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_DTA_GNDP_AC_CNT): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        dtaGndpAcCnt: action.payload?.data,
      };
    case REQUEST(FETCH_DTA_GNDP_AC_CNT): // 조회 요청
      return {
        ...state,
        loading: true,
      };
    case FAILURE(FETCH_DTA_GNDP_AC_CNT): // 조회실패
      return {
        ...state,
        dtaGndpAcCnt: [],
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_BBZDT_INF): // 조회 성공
      console.log(action.payload);
      return {
        ...state,
        inqDtSuccess: true,
        inqDtLoading: false,
        inqDt: String(action.payload?.data),
      };
    case REQUEST(FETCH_BBZDT_INF): // 조회 요청
      return {
        ...state,
        inqDtLoading: true,
      };
    case FAILURE(FETCH_BBZDT_INF): // 조회실패
      return {
        ...state,
        inqDt: dayjs().format('YYYYMMDD'),
        inqDtLoading: false,
        inqDtSuccess: false,
      };
    // 기관명
    case SUCCESS(FETCH_IST_INFO_LIST): // 조회 성공
      return {
        ...state,
        istInfoSuccess: true,
        istInfoLoading: false,
        istInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_IST_INFO_LIST): // 조회 요청
      return {
        ...state,
        istInfoLoading: true,
        istInfoSuccess: false,
      };
    case FAILURE(FETCH_IST_INFO_LIST): // 조회실패
      return {
        istInfoLoading: false,
        istInfoSuccess: false,
        istInfoList: [],
      };
    // 부서명
    case SUCCESS(FETCH_DEPT_INFO_LIST): // 조회 성공
      return {
        ...state,
        deptInfoSuccess: true,
        deptInfoLoading: false,
        deptInfoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_DEPT_INFO_LIST): // 조회 요청
      return {
        ...state,
        deptInfoLoading: true,
        deptInfoSuccess: false,
      };
    case FAILURE(FETCH_DEPT_INFO_LIST): // 조회실패
      return {
        ...state,
        deptInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoList: [],
      };
    // 계좌번호
    case SUCCESS(FETCH_ACT_NO_LIST): // 조회 성공
      return {
        ...state,
        actNoSuccess: true,
        actNoLoading: false,
        actNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_ACT_NO_LIST): // 조회 요청
      return {
        ...state,
        actNoLoading: true,
        actNoSuccess: false,
      };
    case FAILURE(FETCH_ACT_NO_LIST): // 조회실패
      return {
        ...state,
        actNoLoading: false,
        actNoSuccess: false,
        actNoList: [],
      };
    // 사업자번호
    case SUCCESS(FETCH_BIZ_NO_LIST): // 조회 성공
      return {
        ...state,
        bizNoSuccess: true,
        bizNoLoading: false,
        bizNoList: action.payload.data?.subDto,
      };
    case REQUEST(FETCH_BIZ_NO_LIST): // 조회 요청
      return {
        ...state,
        bizNoLoading: true,
        bizNoSuccess: false,
      };
    case FAILURE(FETCH_BIZ_NO_LIST): // 조회실패
      return {
        ...state,
        bizNoLoading: false,
        bizNoSuccess: false,
        bizNoList: [],
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        istInfoSuccess: false,
        istInfoLoading: false,
        deptInfoSuccess: false,
        deptInfoLoading: false,
        actNoSuccess: false,
        actNoLoading: false,
        bizNoSuccess: false,
        bizNoLoading: false,
        searchFormValues: action.payload,
      };
    case RESET_INITIAL_STATE:
      return initialState;
    case SET_BEFORE_SEARCH_PARAM:
      return {
        ...state,
        beforeSearchParam: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
        detailsMessage: '',
        actNmListMessage: '',
        success: false,
        detailsSuccess: false,
      };
    default:
      return state;
  }
}

/**
 * 보통예금 현황 조회
 */
export const selectGndpPtsList = (searchParams) => {
  return {
    type: FETCH_GNDP_PTS_LIST,
    payload: fetchGndpPtsList(searchParams),
  };
};

/**
 * 보통예금 현황 조회(중복제거)
 */
export const selectGndpPtsDistinctList = (searchParams) => {
  return {
    type: FETCH_GNDP_PTS_DIS_LIST,
    payload: fetchGndpPtsDistinctList(searchParams),
  };
};

/**
 * 보통예금 현황 건수 조회
 */
export const selectDtaGndpAcCnt = (searchParams) => {
  return {
    type: FETCH_DTA_GNDP_AC_CNT,
    payload: fetchDtaGndpAcCnt(searchParams),
  };
};

/**
 * 전 영업일 조회
 */
export const selectBbzdtInf = (searchParams) => {
  return {
    type: FETCH_BBZDT_INF,
    payload: fetchBbzdtInf(searchParams),
  };
};

/**
 * 메시지초기화
 */
export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

/**
 *
 */
export const setBeforeSearchParam = (searchParams) => {
  return {
    type: SET_BEFORE_SEARCH_PARAM,
    payload: searchParams,
  };
};

//기관명 콤보 조회
export const selectIstInfoList = (searchParam) => {
  return {
    type: FETCH_IST_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '922', ...searchParam }),
  };
};

//부서명 콤보 조회
export const selectDeptInfoList = (searchParam) => {
  return {
    type: FETCH_DEPT_INFO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '174', ...searchParam }),
  };
};

//계좌번호 콤보 조회
export const selectActNoList = (searchParam) => {
  return {
    type: FETCH_ACT_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '206', ...searchParam }),
  };
};

//사업자번호 콤보 조회
export const selectBizNoList = (searchParam) => {
  return {
    type: FETCH_BIZ_NO_LIST,
    payload: fetchCommonComboList({ slt_box_no: '044', ...searchParam }),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 상태초기화
 */
export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};
