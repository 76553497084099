import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import {
  fetchEtcFdtsPrtsList,
  fetchEtcFdtsPrtsWbkList,
  updateEtcFdtsPrtsList,
  fetchPrintYn,
} from '../../../lib/etc/etc0101m01.api';
import { fetchAfbzInf } from '../../../lib/fxp/GndpPts.api';
import dayjs from 'dayjs';

// actions
const FETCH_FDTS_PRTS = 'etcFdtsPrts/FETCH_FDTS_PRTS';
const UPDATE_FDTS_PRTS = 'etcFdtsPrts/UPDATE_FDTS_PRTS';
const FETCH_FDTS_PRTS_WBK = 'etcFdtsPrts/FETCH_FDTS_PRTS_WBK';
const FETCH_PRINT_YN = 'etcFdtsPrts/FETCH_PRINT_YN';

const RESET_STATE = 'etcFdtsPrts/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'etcFdtsPrts/SAVE_SEARCH_FORM_VALUES';
const FETCH_BBZDT_INF = 'GndpPts/FETCH_BBZDT_INF';

export const initFormValues = {
  inq_seg: '1', // 일자구분
  inq_dt: dayjs().format('YYYY-MM-DD'), // 일자
  iche_gbn: '0', // 이체구분
  inq_sdt: dayjs().format('YYYY-MM-DD'),
  inq_edt: dayjs().format('YYYY-MM-DD'),
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  updateLoading: false, // 수정
  updateSuccess: false, // 수정
  receiptLoading: false, // 영수증
  receiptSuccess: false, // 영수증
  receiptList: [], // 영수증
  totalCount: 0,
  updateCount: 0, // 수정
  receiptCount: 0, // 영수증
  inqDtSuccess: false,
  inqSegList: [
    { id: '1', label: '이체일자' },
    { id: '2', label: '수납일자' },
  ],
  icheGbnList: [
    { id: '0', label: '전체' },
    { id: '1', label: '교환' },
    { id: '2', label: '차액' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function etcFdtsPrts(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_FDTS_PRTS): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
      };
    case REQUEST(FETCH_FDTS_PRTS): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_FDTS_PRTS): // 조회 실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_FDTS_PRTS): // 수정 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateCount: action.payload.data?.resultCnt,
      };
    case REQUEST(UPDATE_FDTS_PRTS): // 수정 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case FAILURE(UPDATE_FDTS_PRTS): // 수정 실패
      return {
        ...initialState,
        updateLoading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_FDTS_PRTS_WBK): // 영수증 조회 성공
      return {
        ...state,
        receiptSuccess: true,
        receiptLoading: false,
        receiptList: action.payload.data?.main_receipt,
        receiptCount: action.payload.data?.main_receipt_cnt,
      };
    case REQUEST(FETCH_FDTS_PRTS_WBK): // 영수증 조회 요청
      return {
        ...state,
        receiptLoading: true,
        receiptSuccess: false,
      };
    case FAILURE(FETCH_FDTS_PRTS_WBK): // 영수증 조회 실패
      return {
        ...initialState,
        receiptLoading: false,
        receiptSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(FETCH_BBZDT_INF): // 조회 성공
      console.log(action.payload);
      return {
        ...state,
        inqDtSuccess: true,
        inqDtLoading: false,
        inqDt: String(action.payload?.data),
      };
    case REQUEST(FETCH_BBZDT_INF): // 조회 요청
      return {
        ...state,
        inqDtLoading: true,
      };
    case FAILURE(FETCH_BBZDT_INF): // 조회실패
      return {
        ...state,
        inqDt: dayjs().format('YYYYMMDD'),
        inqDtLoading: false,
        inqDtSuccess: false,
      };
    case SUCCESS(FETCH_PRINT_YN): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
      };
    case REQUEST(FETCH_PRINT_YN): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
      };
    case FAILURE(FETCH_PRINT_YN): // 조회 실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 기타 - 자금이체 - 자금이체내역표
 */
export const selectEtcFdtsPrtsList = (searchParams) => {
  return {
    type: FETCH_FDTS_PRTS,
    payload: fetchEtcFdtsPrtsList(searchParams),
  };
};

/**
 * 기타 - 자금이체 - 자금이체내역표 (수정)
 */
export const updateEtcFdtsPrts = (dtoList) => {
  return {
    type: UPDATE_FDTS_PRTS,
    payload: updateEtcFdtsPrtsList(dtoList),
  };
};

/**
 * 기타 - 자금이체 - 자금이체내역표 (영수증)
 */
export const selectEtcFdtsPrtsWbkList = (searchParams) => {
  return {
    type: FETCH_FDTS_PRTS_WBK,
    payload: fetchEtcFdtsPrtsWbkList(searchParams),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};

/**
 * 승인·집행 불일치 상태초기화
 */
export const resetEtcFdtsPrts = () => {
  return {
    type: RESET_STATE,
  };
};

/**
 * 다음 영업일 조회
 */
export const selectAfbzInf = (searchParams) => {
  return {
    type: FETCH_BBZDT_INF,
    payload: fetchAfbzInf(searchParams),
  };
};

/**
 * 출력 가능여부 조회
 */
export const selectPrintYn = (searchParams) => {
  return {
    type: FETCH_PRINT_YN,
    payload: fetchPrintYn(searchParams),
  };
};
