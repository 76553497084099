import Highcharts from 'highcharts';
import { dataFormat } from '../common/utils/utils';

// 제로페이 승인내역 조회 - 기관 테이블
export const zpbApvPrtsInitTableColumnsByIst = {
  fields: [
    { fieldName: 'apv_dis' },
    { fieldName: 'wdr_acnm' },
    // { fieldName: 'gov_biz_nm' },
    { fieldName: 'card_no' },
    { fieldName: 'pbam_acno' },
    { fieldName: 'mo_act_no' },
    { fieldName: 'apv_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd' },
    { fieldName: 'apv_tm' },
    { fieldName: 'zpay_trn_srno' },
    { fieldName: 'ehojo_apv_no' },
    { fieldName: 'can_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd' },
    { fieldName: 'spl_am', dataType: 'number' },
    { fieldName: 'vat', dataType: 'number' },
    { fieldName: 'svcch', dataType: 'number' },
    { fieldName: 'apv_sum_am', dataType: 'number' },
    { fieldName: 'te_mng_mok_cd' },
    { fieldName: 'te_mng_mok_nm' },
    { fieldName: 'wdr_bkw_acno' },
    { fieldName: 'mch_nm' },
    { fieldName: 'mch_biz_no' },
    { fieldName: 'mch_tel_no' },
    { fieldName: 'mch_rpspe_nm' },
    { fieldName: 'cdcom_mng_bzctg_nm' },
    { fieldName: 'mch_zip_cd' },
    { fieldName: 'mch_addr_1' },
    { fieldName: 'frg_use_dis' },
    { fieldName: 'trn_cur_cd' },
    { fieldName: 'user_id' },
    { fieldName: 'master_id' },
    { fieldName: 'spl_am1' },
    { fieldName: 'prd_dis' }, // report 조회용
  ],
  columns: [
    { fieldName: 'apv_dis', width: 50, fillWidth: 0, header: '구분' },
    // { fieldName: 'gov_biz_nm', width: 150, fillWidth: 1, header: '기관', styleName: 'text-left' },
    { fieldName: 'wdr_acnm', width: 180, fillWidth: 1, header: '부서', styleName: 'text-left' },
    {
      fieldName: 'card_no',
      width: 200,
      fillWidth: 0,
      header: '제로페이번호',
      textFormat: '([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4});$1-$2-$3-$4',
    },
    {
      fieldName: 'pbam_acno',
      width: 160,
      fillWidth: 0,
      header: '공금계좌',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    {
      fieldName: 'mo_act_no',
      width: 160,
      fillWidth: 0,
      header: '공금모계좌',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    { fieldName: 'apv_dt', width: 100, fillWidth: 0, header: '승인일', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'apv_tm', width: 80, fillWidth: 0, header: '승인시각' },
    { fieldName: 'zpay_trn_srno', width: 150, fillWidth: 0, header: '제로페이 승인번호' },
    { fieldName: 'ehojo_apv_no', width: 100, fillWidth: 0, header: 'E호조 승인번호' },
    { fieldName: 'can_dt', width: 100, fillWidth: 0, header: '취소일', datetimeFormat: 'yyyy.MM.dd' },
    {
      fieldName: 'spl_am',
      width: 90,
      fillWidth: 0,
      header: '공급가액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    { fieldName: 'vat', width: 80, fillWidth: 0, header: '부가세', styleName: 'text-right', numberFormat: '#,##0' },
    { fieldName: 'svcch', width: 80, fillWidth: 0, header: '봉사료', styleName: 'text-right', numberFormat: '#,##0' },
    {
      fieldName: 'apv_sum_am',
      width: 100,
      fillWidth: 0,
      header: '승인 금액(합계)',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    { fieldName: 'te_mng_mok_cd', width: 80, fillWidth: 0, header: '비목코드' },
    { fieldName: 'te_mng_mok_nm', width: 150, fillWidth: 0, header: '비목명', styleName: 'text-left' },
    { fieldName: 'wdr_bkw_acno', width: 130, fillWidth: 0, header: '출금계좌' },
    { fieldName: 'mch_nm', width: 200, fillWidth: 0, header: '가맹점명', styleName: 'text-left' },
    {
      fieldName: 'mch_biz_no',
      width: 110,
      fillWidth: 0,
      header: '사업자번호',
      textFormat: '([0-9]{3})([0-9]{2})([0-9]{4})([0-9]{1});$1-**-****$4',
    },
    { fieldName: 'mch_tel_no', width: 120, fillWidth: 0, header: '전화번호', styleName: 'text-left' },
    { fieldName: 'mch_rpspe_nm', width: 130, fillWidth: 0, header: '대표자명', styleName: 'text-left' },
    { fieldName: 'cdcom_mng_bzctg_nm', width: 140, fillWidth: 0, header: '업종명', styleName: 'text-left' },
    { fieldName: 'mch_zip_cd', width: 80, fillWidth: 0, header: '우편번호' },
    { fieldName: 'mch_addr_1', width: 300, fillWidth: 0, header: '동주소', styleName: 'text-left' },
    { fieldName: 'frg_use_dis', width: 60, fillWidth: 0, header: '해외여부' },
    { fieldName: 'spl_am1', width: 80, fillWidth: 0, header: '해외현지금액', styleName: 'text-right' },
    { fieldName: 'trn_cur_cd', width: 70, fillWidth: 0, header: '사용통화' },
    {
      fieldName: 'user_id',
      width: 110,
      fillWidth: 0,
      header: '사용자ID',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = `<button type="button" class="text-btn">${cell.value}</Button>`;
          return str;
        },
      },
    },
    {
      fieldName: 'master_id',
      width: 110,
      fillWidth: 0,
      header: '마스터ID',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = `<button type="button" class="text-btn">${cell.value}</Button>`;
          return str;
        },
      },
    },
  ],
  layout: [
    'apv_dis',
    'wdr_acnm',
    // 'gov_biz_nm',
    'card_no',
    'pbam_acno',
    'mo_act_no',
    'apv_dt',
    'apv_tm',
    'zpay_trn_srno',
    'ehojo_apv_no',
    'can_dt',
    'spl_am',
    'vat',
    'svcch',
    'apv_sum_am',
    'te_mng_mok_cd',
    'te_mng_mok_nm',
    'wdr_bkw_acno',
    'mch_nm',
    {
      name: 'group',
      direction: 'horizontal',
      //hideChildHeaders: true,

      items: ['mch_biz_no', 'mch_tel_no', 'mch_rpspe_nm', 'cdcom_mng_bzctg_nm', 'mch_zip_cd', 'mch_addr_1'],
      header: {
        text: '가맹점 정보',
      },
    },

    'frg_use_dis',
    'spl_am1',
    'trn_cur_cd',
    'user_id',
    'master_id',
  ],
};

// 제로페이 승인내역 조회 - 기관 테이블
export const zpbApvPrtsInitTableColumnsByIst2 = {
  fields: [
    { fieldName: 'apv_dis' },
    { fieldName: 'wdr_acnm' },
    { fieldName: 'gov_biz_nm' },
    { fieldName: 'ist_nm' },
    { fieldName: 'card_no' },
    { fieldName: 'pbam_acno' },
    { fieldName: 'mo_act_no' },
    { fieldName: 'apv_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd' },
    { fieldName: 'apv_tm' },
    { fieldName: 'zpay_trn_srno' },
    { fieldName: 'ehojo_apv_no' },
    { fieldName: 'can_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd' },
    { fieldName: 'spl_am', dataType: 'number' },
    { fieldName: 'vat', dataType: 'number' },
    { fieldName: 'svcch', dataType: 'number' },
    { fieldName: 'apv_sum_am', dataType: 'number' },
    { fieldName: 'te_mng_mok_cd' },
    { fieldName: 'te_mng_mok_nm' },
    { fieldName: 'wdr_bkw_acno' },
    { fieldName: 'mch_nm' },
    { fieldName: 'mch_biz_no' },
    { fieldName: 'mch_tel_no' },
    { fieldName: 'mch_rpspe_nm' },
    { fieldName: 'cdcom_mng_bzctg_nm' },
    { fieldName: 'mch_zip_cd' },
    { fieldName: 'mch_addr_1' },
    { fieldName: 'frg_use_dis' },
    { fieldName: 'trn_cur_cd' },
    { fieldName: 'user_id' },
    { fieldName: 'master_id' },
    { fieldName: 'spl_am1' },
    { fieldName: 'prd_dis' }, // report 조회용
  ],
  columns: [
    { fieldName: 'apv_dis', width: 50, fillWidth: 0, header: '구분' },
    { fieldName: 'ist_nm', width: 140, fillWidth: 1, header: '상위기관', styleName: 'text-left' },
    { fieldName: 'gov_biz_nm', width: 140, fillWidth: 1, header: '기관', styleName: 'text-left' },
    { fieldName: 'wdr_acnm', width: 180, fillWidth: 1, header: '부서', styleName: 'text-left' },
    {
      fieldName: 'card_no',
      width: 200,
      fillWidth: 0,
      header: '제로페이번호',
      textFormat: '([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4});$1-$2-$3-$4',
    },
    {
      fieldName: 'pbam_acno',
      width: 160,
      fillWidth: 0,
      header: '공금계좌',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    {
      fieldName: 'mo_act_no',
      width: 160,
      fillWidth: 0,
      header: '공금모계좌',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    { fieldName: 'apv_dt', width: 100, fillWidth: 0, header: '승인일', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'apv_tm', width: 80, fillWidth: 0, header: '승인시각' },
    { fieldName: 'zpay_trn_srno', width: 150, fillWidth: 0, header: '제로페이 승인번호' },
    { fieldName: 'ehojo_apv_no', width: 100, fillWidth: 0, header: 'E호조 승인번호' },
    { fieldName: 'can_dt', width: 100, fillWidth: 0, header: '취소일', datetimeFormat: 'yyyy.MM.dd' },
    {
      fieldName: 'spl_am',
      width: 90,
      fillWidth: 0,
      header: '공급가액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    { fieldName: 'vat', width: 80, fillWidth: 0, header: '부가세', styleName: 'text-right', numberFormat: '#,##0' },
    { fieldName: 'svcch', width: 80, fillWidth: 0, header: '봉사료', styleName: 'text-right', numberFormat: '#,##0' },
    {
      fieldName: 'apv_sum_am',
      width: 100,
      fillWidth: 0,
      header: '승인 금액(합계)',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    { fieldName: 'te_mng_mok_cd', width: 80, fillWidth: 0, header: '비목코드' },
    { fieldName: 'te_mng_mok_nm', width: 150, fillWidth: 0, header: '비목명', styleName: 'text-left' },
    { fieldName: 'wdr_bkw_acno', width: 130, fillWidth: 0, header: '출금계좌' },
    { fieldName: 'mch_nm', width: 200, fillWidth: 0, header: '가맹점명', styleName: 'text-left' },
    {
      fieldName: 'mch_biz_no',
      width: 110,
      fillWidth: 0,
      header: '사업자번호',
      textFormat: '([0-9]{3})([0-9]{2})([0-9]{4})([0-9]{1});$1-**-****$4',
    },
    { fieldName: 'mch_tel_no', width: 120, fillWidth: 0, header: '전화번호', styleName: 'text-left' },
    { fieldName: 'mch_rpspe_nm', width: 130, fillWidth: 0, header: '대표자명', styleName: 'text-left' },
    { fieldName: 'cdcom_mng_bzctg_nm', width: 140, fillWidth: 0, header: '업종명', styleName: 'text-left' },
    { fieldName: 'mch_zip_cd', width: 80, fillWidth: 0, header: '우편번호' },
    { fieldName: 'mch_addr_1', width: 300, fillWidth: 0, header: '동주소', styleName: 'text-left' },
    { fieldName: 'frg_use_dis', width: 60, fillWidth: 0, header: '해외여부' },
    { fieldName: 'spl_am1', width: 80, fillWidth: 0, header: '해외현지금액', styleName: 'text-right' },
    { fieldName: 'trn_cur_cd', width: 70, fillWidth: 0, header: '사용통화' },
    {
      fieldName: 'user_id',
      width: 110,
      fillWidth: 0,
      header: '사용자ID',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = `<button type="button" class="text-btn">${cell.value}</Button>`;
          return str;
        },
      },
    },
    {
      fieldName: 'master_id',
      width: 110,
      fillWidth: 0,
      header: '마스터ID',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = `<button type="button" class="text-btn">${cell.value}</Button>`;
          return str;
        },
      },
    },
  ],
  layout: [
    'apv_dis',
    'wdr_acnm',
    'ist_nm',
    'gov_biz_nm',
    'card_no',
    'pbam_acno',
    'mo_act_no',
    'apv_dt',
    'apv_tm',
    'zpay_trn_srno',
    'ehojo_apv_no',
    'can_dt',
    'spl_am',
    'vat',
    'svcch',
    'apv_sum_am',
    'te_mng_mok_cd',
    'te_mng_mok_nm',
    'wdr_bkw_acno',
    'mch_nm',
    {
      name: 'group',
      direction: 'horizontal',
      //hideChildHeaders: true,

      items: ['mch_biz_no', 'mch_tel_no', 'mch_rpspe_nm', 'cdcom_mng_bzctg_nm', 'mch_zip_cd', 'mch_addr_1'],
      header: {
        text: '가맹점 정보',
      },
    },

    'frg_use_dis',
    'spl_am1',
    'trn_cur_cd',
    'user_id',
    'master_id',
  ],
};

// 제로페이 승인내역 조회 - 산하기관 테이블
export const zpbApvPrtsInitTableColumnsByUninIst = {
  fields: [
    { fieldName: 'apv_dis' },
    { fieldName: 'user_type' },
    { fieldName: 'gov_biz_nm' },
    { fieldName: 'card_no' },
    { fieldName: 'apv_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd' },
    { fieldName: 'apv_tm' },
    { fieldName: 'zpay_trn_srno' },
    { fieldName: 'ehojo_apv_no' },
    { fieldName: 'can_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd' },
    { fieldName: 'apl_am', dataType: 'number' },
    { fieldName: 'vat', dataType: 'number' },
    { fieldName: 'svcch', dataType: 'number' },
    { fieldName: 'apv_sum_am', dataType: 'number' },
    { fieldName: 'wdr_bkw_acno' },
    { fieldName: 'mch_nm' },
    // { fieldName: 'mch_zip_cd1' }, //
    { fieldName: 'mch_biz_no' },
    { fieldName: 'mch_tel_no' },
    { fieldName: 'mch_rpspe_nm' },
    { fieldName: 'cdcom_mng_bzctg_nm' },
    { fieldName: 'mch_zip_cd' },
    { fieldName: 'mch_addr_1' },
    { fieldName: 'frg_use_dis' },
    { fieldName: 'spl_am1' },
    { fieldName: 'trn_cur_cd' },
    { fieldName: 'user_id' },
    { fieldName: 'master_id' },
    { fieldName: 'prd_dis' }, // report 조회용
  ],
  columns: [
    { fieldName: 'apv_dis', width: 50, fillWidth: 0, header: '구분' },
    { fieldName: 'user_type', width: 150, fillWidth: 0, header: '사용자 구분' },
    { fieldName: 'gov_biz_nm', width: 180, fillWidth: 1, header: '기관(부서명)', styleName: 'text-left' },
    {
      fieldName: 'card_no',
      width: 200,
      fillWidth: 0,
      header: '제로페이번호',
      textFormat: '([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4});$1-$2-$3-$4',
    },
    { fieldName: 'apv_dt', width: 100, fillWidth: 0, header: '승인일', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'apv_tm', width: 80, fillWidth: 0, header: '승인시각' },
    { fieldName: 'zpay_trn_srno', width: 150, fillWidth: 0, header: '제로페이 승인번호' },
    { fieldName: 'ehojo_apv_no', width: 100, fillWidth: 0, header: 'E호조 승인번호' },
    { fieldName: 'can_dt', width: 100, fillWidth: 0, header: '취소일', datetimeFormat: 'yyyy.MM.dd' },
    {
      fieldName: 'apl_am',
      width: 90,
      fillWidth: 0,
      header: '공급가액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    { fieldName: 'vat', width: 120, fillWidth: 0, header: '부가세', styleName: 'text-right', numberFormat: '#,##0' },
    { fieldName: 'svcch', width: 120, fillWidth: 0, header: '봉사료', styleName: 'text-right', numberFormat: '#,##0' },
    {
      fieldName: 'apv_sum_am',
      width: 120,
      fillWidth: 0,
      header: '승인금액(합계)',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    { fieldName: 'wdr_bkw_acno', width: 130, fillWidth: 0, header: '출금계좌' },
    { fieldName: 'mch_nm', width: 200, fillWidth: 0, header: '가맹점명', styleName: 'text-left' },
    // { fieldName: 'mch_zip_cd1', width: 100, fillWidth: 0, header: '가맹점번호' }, //
    {
      fieldName: 'mch_biz_no',
      width: 110,
      fillWidth: 0,
      header: '사업자번호',
      textFormat: '([0-9]{3})([0-9]{2})([0-9]{4})([0-9]{1});$1-**-****$4',
    },
    { fieldName: 'mch_tel_no', width: 130, fillWidth: 0, header: '전화번호' },
    { fieldName: 'mch_rpspe_nm', width: 100, fillWidth: 0, header: '대표자명', styleName: 'text-left' },
    { fieldName: 'cdcom_mng_bzctg_nm', width: 140, fillWidth: 0, header: '업종명', styleName: 'text-left' },
    { fieldName: 'mch_zip_cd', width: 80, fillWidth: 0, header: '우편번호' },
    { fieldName: 'mch_addr_1', width: 300, fillWidth: 0, header: '동주소', styleName: 'text-left' },
    { fieldName: 'frg_use_dis', width: 60, fillWidth: 0, header: '해외여부' },
    { fieldName: 'spl_am1', width: 80, fillWidth: 0, header: '해외현지금액', styleName: 'text-right' },
    { fieldName: 'trn_cur_cd', width: 70, fillWidth: 0, header: '사용통화' },
    {
      fieldName: 'user_id',
      width: 110,
      fillWidth: 0,
      header: '사용자ID',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = `<button type="button" class="text-btn">${cell.value}</button>`;
          return str;
        },
      },
    },
    {
      fieldName: 'master_id',
      width: 110,
      fillWidth: 0,
      header: '마스터ID',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = `<button type="button" class="text-btn">${cell.value}</button>`;
          return str;
        },
      },
    },
    // { fieldName: 'user_id', width: 110, fillWidth: 0, header: '사용자ID' },
    // { fieldName: 'master_id', width: 110, fillWidth: 0, header: '마스터ID' },
  ],
  layout: [
    'apv_dis',
    'user_type',
    'gov_biz_nm',
    'card_no',
    'apv_dt',
    'apv_tm',
    'zpay_trn_srno',
    'ehojo_apv_no',
    'can_dt',
    'apl_am',
    'vat',
    'svcch',
    'apv_sum_am',
    'wdr_bkw_acno',
    'mch_nm',
    // 'mch_zip_cd1', //

    {
      name: 'group',
      direction: 'horizontal',
      //hideChildHeaders: true,

      items: ['mch_biz_no', 'mch_tel_no', 'mch_rpspe_nm', 'cdcom_mng_bzctg_nm', 'mch_zip_cd', 'mch_addr_1'],
      header: {
        text: '가맹점 정보',
      },
    },

    'frg_use_dis',
    'spl_am1',
    'trn_cur_cd',
    'user_id',
    'master_id',
  ],
};

// 제로페이 승인내역 조회 - 사용자 요약 조회
export const zpbApvPrtInitDetail = {
  infoList: [
    {
      id: 1,
      children: [
        {
          id: 'user_id',
          title: '사용자 ID',
          text: '',
        },
      ],
    },
    {
      id: 2,
      children: [
        {
          id: 'co_cus_mst_id',
          title: '마스터 ID',
          text: '',
        },
      ],
    },
  ],
  dataList: [
    {
      id: 1,
      children: [
        {
          id: 'usnm',
          title: '사용자명',
          text: '',
        },
        {
          id: 'use_yn',
          title: '사용여부',
          text: '',
        },
        {
          id: 'once_ts_lmt',
          title: '1회 이체한도',
          text: '',
          isNum: true,
        },
        {
          id: 'one_day_ts_lmt',
          title: '1일 이체한도',
          text: '',
          isNum: true,
        },
      ],
    },
    {
      id: 2,
      children: [
        {
          id: 'zero_pay_user_no',
          title: '제로페이 이용자 번호',
          text: '',
        },
        {
          id: 'upche_gbn_nm',
          title: '사용자구분',
          text: '',
        },
        {
          id: 'rgs_dt',
          title: '가입일',
          text: '',
        },
      ],
    },
    {
      id: '3',
      children: [
        {
          id: 'dept_nm',
          title: '부서명',
          text: '',
        },
        {
          id: 'hp_no',
          title: '핸드폰 번호',
          text: '',
        },
        {
          id: 'can_dt',
          title: '해지일',
          text: '',
        },
      ],
    },
  ],
};

// 제로페이 집행내역 조회 - 테이블
export const zpbExuPrtsInitTableColumns = {
  fields: [
    { fieldName: 'jh_gb' },
    { fieldName: 'card_no' },
    { fieldName: 'wdr_acnm' },
    { fieldName: 'apv_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd' },
    { fieldName: 'apv_tm' },
    { fieldName: 'zpay_trn_srno' },
    { fieldName: 'ehojo_apv_no' },
    { fieldName: 'can_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd' },
    { fieldName: 'apv_sum_am', dataType: 'number' },
    { fieldName: 'te_mng_mok_cd' },
    { fieldName: 'te_mng_mok_nm' },
    { fieldName: 'mch_nm' },
    { fieldName: 'gov_biz_nm' },
    { fieldName: 'dept_nm' },
    { fieldName: 'cmd_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd' },
    { fieldName: 'pay_cmd_no' },
    { fieldName: 'tot_pay_cmd_amt' },
    { fieldName: 'acct_no' },
    { fieldName: 'user_id' },
    { fieldName: 'master_id' },
    { fieldName: 'hp_no' },
    { fieldName: 'rst_err_msg1' },

    { fieldName: 'mch_biz_no' },
    { fieldName: 'mch_addr_1' },
    { fieldName: 'sms_dept' },
  ],
  columns: [
    { fieldName: 'jh_gb', width: 83, fillWidth: 0, header: '집행구분' },
    {
      fieldName: 'card_no',
      width: 170,
      fillWidth: 1,
      header: '제로페이번호',
      textFormat: '([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4});$1-$2-$3-$4',
    },
    { fieldName: 'wdr_acnm', width: 200, fillWidth: 0, header: '부서명', styleName: 'text-left' },
    { fieldName: 'apv_dt', width: 100, fillWidth: 0, header: '승인일', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'apv_tm', width: 100, fillWidth: 0, header: '승인시각' },
    { fieldName: 'zpay_trn_srno', width: 140, fillWidth: 0, header: '제로페이 승인번호' },
    { fieldName: 'ehojo_apv_no', width: 120, fillWidth: 0, header: 'E호조 승인번호' },
    { fieldName: 'can_dt', width: 110, fillWidth: 0, header: '취소일', datetimeFormat: 'yyyy.MM.dd' },
    {
      fieldName: 'apv_sum_am',
      width: 90,
      fillWidth: 0,
      header: '승인금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    { fieldName: 'te_mng_mok_cd', width: 80, fillWidth: 0, header: '비목코드' },
    { fieldName: 'te_mng_mok_nm', width: 160, fillWidth: 0, header: '비목명', styleName: 'text-left' },
    { fieldName: 'mch_nm', width: 200, fillWidth: 0, header: '가맹점명', styleName: 'text-left' },
    { fieldName: 'gov_biz_nm', width: 120, fillWidth: 0, header: '기관명', styleName: 'text-left' },
    { fieldName: 'dept_nm', width: 130, fillWidth: 0, header: '부서명' },
    { fieldName: 'cmd_dt', width: 130, fillWidth: 0, header: '집행일자', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'pay_cmd_no', width: 100, fillWidth: 0, header: '지급명령번호' },
    {
      fieldName: 'tot_pay_cmd_amt',
      width: 130,
      fillWidth: 0,
      header: '지급명령금액',
      styleName: 'text-right',
    },
    { fieldName: 'acct_no', width: 150, fillWidth: 0, header: '계좌번호' },
    { fieldName: 'user_id', width: 120, fillWidth: 0, header: '사용자ID' },
    { fieldName: 'master_id', width: 120, fillWidth: 0, header: '마스터ID' },
    { fieldName: 'hp_no', width: 130, fillWidth: 0, header: '핸드폰번호' },
    { fieldName: 'rst_err_msg1', width: 60, fillWidth: 0, header: '비고' },
  ],
};

// 제로페이 승인•집행 불일치조회 - 테이블
export const zpbApvExuNacrdInitTableColumns = {
  fields: [
    { fieldName: 'trnDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'ebnkUtzpeNo' },
    { fieldName: 'pbamAcno' },
    { fieldName: 'zpayTrnSrno' },
    { fieldName: 'pgTrnAm', dataType: 'number' },
    { fieldName: 'payPbamAcno' },
    { fieldName: 'acclTrnDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'rcvPbamAcno' },
    { fieldName: 'prcCmplYn' },
  ],
  columns: [
    { fieldName: 'trnDt', width: 90, fillWidth: 0, header: '승인일자', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'ebnkUtzpeNo', width: 100, fillWidth: 1, header: '이용자ID' },
    {
      fieldName: 'pbamAcno',
      width: 120,
      fillWidth: 0,
      header: '일상경비계좌',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    { fieldName: 'zpayTrnSrno', width: 120, fillWidth: 1, header: '승인번호' },
    {
      fieldName: 'pgTrnAm',
      width: 70,
      fillWidth: 1,
      header: '승인금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'payPbamAcno',
      width: 120,
      fillWidth: 1,
      header: '지급계좌',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    { fieldName: 'acclTrnDt', width: 90, fillWidth: 0, header: '집행일자', datetimeFormat: 'yyyy.MM.dd' },
    {
      fieldName: 'rcvPbamAcno',
      width: 120,
      fillWidth: 0,
      header: '집행계좌',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    {
      fieldName: 'prcCmplYn',
      width: 60,
      fillWidth: 0,
      header: '비고',
      lookupDisplay: true,
      values: ['Y', 'N'],
      labels: ['일치', '불일치'],
    },
  ],
  layout: [
    'trnDt',

    {
      name: 'companyGroup1',
      direction: 'horizontal',
      items: ['ebnkUtzpeNo', 'pbamAcno', 'zpayTrnSrno', 'pgTrnAm', 'payPbamAcno'],
      header: {
        text: '승인정보',
      },
    },
    {
      name: 'companyGroup1',
      direction: 'horizontal',
      items: ['acclTrnDt', 'rcvPbamAcno'],
      header: {
        text: '집행정보',
      },
    },

    'prcCmplYn',
  ],
};

// 제로페이 일자/결제방식별 거래량 조회 - 테이블
export const zpb0107v01InitTableColumns = {
  fields: [
    { fieldName: 'apv_dt' },
    { fieldName: 'tot_cnt', dataType: 'number' },
    { fieldName: 'tot_sum_am', dataType: 'number' },
    { fieldName: 'cpm_cnt', dataType: 'number' },
    { fieldName: 'cpm_sum_am', dataType: 'number' },
    { fieldName: 'mpm_cnt', dataType: 'number' },
    { fieldName: 'mpm_sum_am', dataType: 'number' },
    { fieldName: 'tag_cnt', dataType: 'number' },
    { fieldName: 'tag_sum_am', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'apv_dt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '결제일',
    },
    {
      fieldName: 'tot_cnt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '총 결제건수',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'tot_sum_am',
      type: 'data',
      width: '120',
      fillWidth: 1,
      header: '총 결제금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'cpm_cnt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: 'CPM결제건수',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'cpm_sum_am',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: 'CPM결제금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'mpm_cnt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: 'MPM결제건수',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'mpm_sum_am',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: 'MPM결제금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'tag_cnt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '태깅결제건수',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'tag_sum_am',
      type: 'data',
      width: '120',
      fillWidth: 1,
      header: '태깅결제금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
  ],
};

// 제로페이 사용자 전용앱 로그인 이력조회 - 테이블
export const zpb0205v01InitTableColumns = {
  fields: [
    { fieldName: 'rnum' },
    { fieldName: 'lst_cnct_dtm', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd hh:mm:ss' },
    { fieldName: 'user_id' },
    { fieldName: 'crtf_tycd' },
    { fieldName: 'crtf_sess_key' },
  ],
  columns: [
    {
      fieldName: 'lst_cnct_dtm',
      type: 'data',
      width: '200',
      fillWidth: 0,
      header: '로그인일시',
      datetimeFormat: 'yyyy.MM.dd hh:mm:ss',
    },
    {
      fieldName: 'user_id',
      type: 'data',
      width: '200',
      fillWidth: 0,
      header: '사용자ID',
    },
    {
      fieldName: 'crtf_tycd',
      type: 'data',
      width: '200',
      fillWidth: 0,
      header: '로그인방법',
    },
    {
      fieldName: 'crtf_sess_key',
      type: 'data',
      // width: '190',
      fillWidth: 1,
      header: '세션키',
    },
  ],
};

// 제로페이 공지함 - 테이블
export const zpb0207m01InitTableColumns = {
  fields: [
    { fieldName: 'bltn_tycd_v' },
    { fieldName: 'recp_tgt_dtls_tycd_v' },
    { fieldName: 'bltn_dt_v' },
    { fieldName: 'bltn_yn' },
    { fieldName: 'bltn_titl' },
    { fieldName: 'bltn_srno' },
    { fieldName: 'bltn_tycd' },
    { fieldName: 'bltn_dtls_tycd' },
    { fieldName: 'bltn_stdt' },
    { fieldName: 'bltn_eddt' },
    { fieldName: 'bltn_adday_yn' },
    { fieldName: 'bltn_st_tm' },
    { fieldName: 'bltn_ed_tm' },
    { fieldName: 'recp_tgt_tycd' },
    { fieldName: 'recp_tgt_dtls_tycd' },
    { fieldName: 'recp_trm_ver_tycd' },
    { fieldName: 'recp_trm_os_ver' },
    { fieldName: 'app_ver_equ_yn' },
    { fieldName: 'app_apl_ver' },
    { fieldName: 'app_ver_a' },
    { fieldName: 'app_ver_i' },
    { fieldName: 'bltn_txt' },
    { fieldName: 'fst_rgpe_v' },
  ],
  columns: [
    {
      fieldName: 'bltn_tycd_v',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '공지유형',
    },
    {
      fieldName: 'recp_tgt_dtls_tycd_v',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: 'OS유형',
    },
    {
      fieldName: 'bltn_dt_v',
      type: 'data',
      width: '300',
      fillWidth: 0,
      header: '공지기간(시간대)',
    },
    {
      fieldName: 'bltn_yn',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '게시여부',
    },
    {
      fieldName: 'fst_rgpe_v',
      type: 'data',
      width: '180',
      fillWidth: 0,
      header: '등록자',
    },
    {
      fieldName: 'bltn_titl',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '공지제목',
      styleName: 'text-left',
    },
  ],
};

// 제로페이(관리자) 사용자조회 - 테이블(지자체)
export const zpb0201v01InitTableColumns = {
  fields: [
    { fieldName: 'user_id' },
    { fieldName: 'usnm' },
    { fieldName: 'zero_pay_user_no' },
    { fieldName: 'bzno' },
    { fieldName: 'co_cus_mst_id' },
    { fieldName: 'dept_nm' },
    { fieldName: 'use_yn' },
    { fieldName: 'upche_gbn_cd' },
    { fieldName: 'upche_gbn_nm' },
    { fieldName: 'hp_no' },
    { fieldName: 'rgs_dt', dataType: 'datetime', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'can_dt', dataType: 'datetime', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'can_dt_hide' },
    { fieldName: 'wdr_acno' },
    { fieldName: 'pmny_moact' },
    { fieldName: 'wdr_acnm' },
    { fieldName: 'dmd_acno' },
    { fieldName: 'dmd_acnm' },
    { fieldName: 'one_day_ts_lmt', dataType: 'number' },
    { fieldName: 'once_ts_lmt', dataType: 'number' },
    { fieldName: 'user_bal_inq_yn' },
    { fieldName: 'sms_dps_yn' },
    { fieldName: 'sms_non_dps_yn' },
    { fieldName: 'slf_dps_yn' },
    { fieldName: 'ebnk_ctr_dt' },
  ],
  columns: [
    {
      fieldName: 'user_id',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '사용자 ID',
    },
    {
      fieldName: 'usnm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '사용자명',
      styleName: 'text-left',
    },
    {
      fieldName: 'zero_pay_user_no',
      type: 'data',
      width: '170',
      fillWidth: 0,
      header: '제로페이 이용자번호',
    },
    {
      fieldName: 'bzno',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '사업자 등록번호',
    },
    {
      fieldName: 'co_cus_mst_id',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '마스터ID',
    },
    {
      fieldName: 'dept_nm',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '부서명',
      styleName: 'text-left',
    },
    {
      fieldName: 'use_yn',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '사용여부',
    },
    {
      fieldName: 'upche_gbn_nm',
      type: 'data',
      width: '180',
      fillWidth: 0,
      header: '사용자구분',
      styleName: 'text-left',
    },
    {
      fieldName: 'hp_no',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '핸드폰번호',
    },
    {
      fieldName: 'rgs_dt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '가입일',
      datetimeFormat: 'yyyy.MM.dd',
    },
    {
      fieldName: 'can_dt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '해지일',
      datetimeFormat: 'yyyy.MM.dd',
    },
    {
      fieldName: 'wdr_acno',
      type: 'data',
      width: '160',
      fillWidth: 0,
      header: '일상경비계좌번호',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    {
      fieldName: 'wdr_acnm',
      type: 'data',
      width: '240',
      fillWidth: 0,
      header: '일상경비계좌명',
      styleName: 'text-left',
    },
    {
      fieldName: 'pmny_moact',
      type: 'data',
      width: '160',
      fillWidth: 0,
      header: '공금모계좌번호',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    {
      fieldName: 'dmd_acno',
      type: 'data',
      width: '160',
      fillWidth: 0,
      header: '요구불계좌번호',
      textFormat: '([0-9]{4})([0-9]{3})([0-9]{6});$1-$2-$3',
    },
    {
      fieldName: 'dmd_acnm',
      type: 'data',
      width: '240',
      fillWidth: 0,
      header: '요구불계좌명',
      styleName: 'text-left',
    },
    {
      fieldName: 'one_day_ts_lmt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '1일제한한도',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'once_ts_lmt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '1회이체한도',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'user_bal_inq_yn',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '잔액 확인권한',
    },
    {
      fieldName: 'slf_dps_yn',
      type: 'data',
      width: '150',
      fillWidth: 0,
      header: '본인(카카오톡,UMS)수신여부',
    },
    {
      fieldName: 'sms_dps_yn',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '서무권한 카카오톡 수신여부',
    },
    {
      fieldName: 'sms_non_dps_yn',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '서무권한 카카오톡 집행 수신여부',
    },
    {
      fieldName: 'ebnk_ctr_dt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: 'ID 등록일',
      datetimeFormat: 'yyyy.MM.dd',
    },
  ],
  rows: [
    {
      column01: '002YFH00',
      column02: '백소람',
      column03: '0000-0000-0000-0000',
      column04: '000-00-00000',
      column05: '002FD',
      column06: '기획예산과 영등포일상경비',
      column07: 'Y',
      column08: '지자체(시청, 구청 등)',
      column09: '010-0000-0000',
      column10: 'YYYY-MM-DD',
      column11: 'YYYY-MM-DD',
      column12: '0000-00000-000-0',
      column13: '0000-00000-000-0',
      column14: '기획예산과 영등포 일상경비',
      column15: '0000-000-000000',
      column16: '영등포구청 기획예산과 제로페이',
      column17: '1,000,000',
      column18: '1,000,000',
      column19: 'N',
      column20: 'Y',
      column21: 'Y',
      column22: 'Y',
      column23: 'data',
      column24: 'data',
      column25: 'YYYY.MM.DD',
    },
  ],
};

// 제로페이(관리자) 사용자조회 - 테이블(이외)
export const zpb0201v01InitTableColumnsByOther = {
  fields: [
    { fieldName: 'user_id' },
    { fieldName: 'usnm' },
    { fieldName: 'zero_pay_user_no' },
    { fieldName: 'bzno' },
    { fieldName: 'co_cus_mst_id' },
    { fieldName: 'dept_nm' },
    { fieldName: 'use_yn' },
    { fieldName: 'upche_gbn_cd' },
    { fieldName: 'upche_gbn_nm' },
    { fieldName: 'hp_no' },
    { fieldName: 'rgs_dt', dataType: 'datetime', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'can_dt', dataType: 'datetime', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'can_dt_hide' },
    { fieldName: 'wdr_acno' },
    { fieldName: 'pmny_moact' },
    { fieldName: 'wdr_acnm' },
    { fieldName: 'dmd_acno' },
    { fieldName: 'dmd_acnm' },
    { fieldName: 'one_day_ts_lmt', dataType: 'number' },
    { fieldName: 'once_ts_lmt', dataType: 'number' },
    { fieldName: 'user_bal_inq_yn' },
    { fieldName: 'sms_dps_yn' },
    { fieldName: 'sms_non_dps_yn' },
    { fieldName: 'slf_dps_yn' },
    { fieldName: 'ebnk_ctr_dt' },
  ],
  columns: [
    {
      fieldName: 'user_id',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '사용자 ID',
    },
    {
      fieldName: 'usnm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '사용자명',
      styleName: 'text-left',
    },
    {
      fieldName: 'zero_pay_user_no',
      type: 'data',
      width: '170',
      fillWidth: 0,
      header: '제로페이 이용자번호',
    },
    {
      fieldName: 'bzno',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '사업자 등록번호',
    },
    {
      fieldName: 'co_cus_mst_id',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '마스터ID',
    },
    {
      fieldName: 'dept_nm',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '부서명',
      styleName: 'text-left',
    },
    {
      fieldName: 'use_yn',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '사용여부',
    },
    {
      fieldName: 'upche_gbn_nm',
      type: 'data',
      width: '180',
      fillWidth: 0,
      header: '사용자구분',
      styleName: 'text-left',
    },
    {
      fieldName: 'hp_no',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '핸드폰번호',
    },
    {
      fieldName: 'rgs_dt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '가입일',
      datetimeFormat: 'yyyy.MM.dd',
    },
    {
      fieldName: 'can_dt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '해지일',
      datetimeFormat: 'yyyy.MM.dd',
    },
    {
      fieldName: 'wdr_acno',
      type: 'data',
      width: '160',
      fillWidth: 0,
      header: '출금계좌번호',
      textFormat: '([0-9]{4})([0-9]{3})([0-9]{6});$1-$2-$3',
    },
    {
      fieldName: 'wdr_acnm',
      type: 'data',
      width: '240',
      fillWidth: 0,
      header: '출금계좌명',
      styleName: 'text-left',
    },
    {
      fieldName: 'pmny_moact',
      type: 'data',
      width: '160',
      fillWidth: 0,
      header: '공금모계좌번호',
      textFormat: '([0-9]{4})([0-9]{5})([0-9]{3})([0-9]{1});$1-$2-$3-$4',
    },
    {
      fieldName: 'dmd_acno',
      type: 'data',
      width: '160',
      fillWidth: 0,
      header: '요구불계좌번호',
      textFormat: '([0-9]{4})([0-9]{3})([0-9]{6});$1-$2-$3',
    },
    {
      fieldName: 'dmd_acnm',
      type: 'data',
      width: '240',
      fillWidth: 0,
      header: '요구불계좌명',
      styleName: 'text-left',
    },
    {
      fieldName: 'one_day_ts_lmt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '1일제한한도',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'once_ts_lmt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '1회이체한도',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'user_bal_inq_yn',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '잔액 확인권한',
    },
    {
      fieldName: 'slf_dps_yn',
      type: 'data',
      width: '150',
      fillWidth: 0,
      header: '본인(카카오톡,UMS)수신여부',
    },
    {
      fieldName: 'sms_dps_yn',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '서무권한 카카오톡 수신여부',
    },
    {
      fieldName: 'sms_non_dps_yn',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '서무권한 카카오톡 집행 수신여부',
    },
    {
      fieldName: 'ebnk_ctr_dt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: 'ID 등록일',
      datetimeFormat: 'yyyy.MM.dd',
    },
  ],
  rows: [
    {
      column01: '002YFH00',
      column02: '백소람',
      column03: '0000-0000-0000-0000',
      column04: '000-00-00000',
      column05: '002FD',
      column06: '기획예산과 영등포일상경비',
      column07: 'Y',
      column08: '지자체(시청, 구청 등)',
      column09: '010-0000-0000',
      column10: 'YYYY-MM-DD',
      column11: 'YYYY-MM-DD',
      column12: '0000-00000-000-0',
      column13: '0000-00000-000-0',
      column14: '기획예산과 영등포 일상경비',
      column15: '0000-000-000000',
      column16: '영등포구청 기획예산과 제로페이',
      column17: '1,000,000',
      column18: '1,000,000',
      column19: 'N',
      column20: 'Y',
      column21: 'Y',
      column22: 'Y',
      column23: 'data',
      column24: 'data',
      column25: 'YYYY.MM.DD',
    },
  ],
};

// 제로페이(관리자) 카카오톡 전송내역 조회 - 테이블
export const zpb0204v01InitTableColumns = {
  fields: [
    { fieldName: 'trsn_dt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'recp_tel_no' },
    { fieldName: 'message1' },
    { fieldName: 'message2' },
    { fieldName: 'map2' },
    { fieldName: 'rgs_dt', dataType: 'datetime', datetimeFormat: 'yyyy-MM-dd hh:mm:ss' },
    { fieldName: 'eai_tms_rtcd' },
    { fieldName: 'user_id' },
  ],
  columns: [
    {
      fieldName: 'trsn_dt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '발신일자',
      datetimeFormat: 'yyyy.MM.dd',
    },
    {
      fieldName: 'recp_tel_no',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '수신번호',
    },
    {
      fieldName: 'map2',
      type: 'data',
      width: '150',
      fillWidth: 0,
      header: '제로페이 승인번호',
    },
    {
      fieldName: 'user_id',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '사용자 ID',
    },
    {
      fieldName: 'message2',
      type: 'data',
      width: '280',
      fillWidth: 1,
      header: 'SMS/카카오톡 내용',
      styleName: 'text-left',
    },
    {
      fieldName: 'eai_tms_rtcd',
      type: 'data',
      width: '90',
      fillWidth: 0,
      header: '전송여부',
    },
    {
      fieldName: 'rgs_dt',
      type: 'data',
      width: '180',
      fillWidth: 0,
      header: '전송일시',
      datetimeFormat: 'yyyy.MM.dd hh:mm:ss',
    },
  ],
};

// 제로페이(관리자) 가입 및 결제현황 - 테이블
export const zpb0202v01InitTableColumns = {
  fields: [
    { fieldName: 'cus_ist_cd' },
    { fieldName: 'upche_gbn_cd' },
    { fieldName: 'bzno_cnt', dataType: 'number' },
    { fieldName: 'totalcnt', dataType: 'number' },
    { fieldName: 'silcount', dataType: 'number' },
    { fieldName: 'zpay_user_dscd' },
    { fieldName: 'sum1', dataType: 'number' },
    { fieldName: 'cnt1', dataType: 'number' },
    { fieldName: 'sum2', dataType: 'number' },
    { fieldName: 'cnt2', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'upche_gbn_cd',
      type: 'data',
      width: '220',
      fillWidth: 0,
      header: '구분',
      styleName: 'text-left',
      footer: { text: '합계' },
    },
    {
      fieldName: 'bzno_cnt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '기관 사업자 수',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'totalcnt',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '권한부여',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'silcount',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '실(접속)가입자',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'cnt1',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '승인 건수',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'sum1',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '승인 금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'cnt2',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '정산 건수',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'sum2',
      type: 'data',
      width: '100',
      fillWidth: 1,
      header: '정산 금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
  ],
  layout: [
    'upche_gbn_cd',
    {
      name: 'group1',
      direction: 'horizontal',
      items: ['bzno_cnt', 'totalcnt', 'silcount'],
      header: {
        text: '사용자 수',
      },
    },
    {
      name: 'group2',
      direction: 'horizontal',
      items: ['cnt1', 'sum1', 'cnt2', 'sum2'],
      header: {
        text: '거래현황 ',
      },
    },
  ],
};

// 제로페이(관리자) 공공기관 회계담당 번호등록 - 테이블
export const zpb0203v01InitTableColumns = {
  fields: [
    { fieldName: 'user_id' },
    { fieldName: 'zero_pay_user_no' },
    { fieldName: 'bzno' },
    { fieldName: 'biz_nm' },
    { fieldName: 'co_cus_mst_id' },
    { fieldName: 'dept_nm' },
    { fieldName: 'usnm' },
    { fieldName: 'user_id_b' },
    { fieldName: 'biz_no' },
    { fieldName: 'use_yn' },
    { fieldName: 'apv_use_yn' },
    { fieldName: 'dps_user_id' },
    { fieldName: 'hp_no' },
    { fieldName: 'org_dps_user_id' },
    { fieldName: 'org_hp_no' },
  ],
  columns: [
    {
      fieldName: 'bzno',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '사업자번호',
    },
    {
      fieldName: 'biz_nm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '기관명',
      styleName: 'text-left',
    },
    {
      fieldName: 'user_id',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '사용자ID',
      styleName: 'text-left',
    },
    {
      fieldName: 'dept_nm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '부서명',
      styleName: 'text-left',
    },
    {
      fieldName: 'usnm',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '성명',
      styleName: 'text-left',
    },
    {
      fieldName: 'hp_no',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: { text: '회계/감사 SMS수신번호', styleName: ' bg-color01' },
      textFormat: '([0-9]{3})([0-9]{4})([0-9]{4});$1-$2-$3',
    },
    {
      fieldName: 'dps_user_id',
      type: 'data',
      width: '135',
      fillWidth: 0,

      header: { text: '회계/감사 제로페이 ID', styleName: ' bg-color01' },
    },
    {
      fieldName: 'use_yn',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: { text: 'SMS/PUSH 수신 여부', styleName: ' bg-color01' },
    },
    {
      fieldName: 'org_hp_no',
      type: 'data',
      width: '200',
      fillWidth: 1,
      visible: false,
    },
    {
      fieldName: 'org_dps_user_id',
      type: 'data',
      width: '150',
      fillWidth: 1,
      visible: false,
    },
    // {
    //   type: 'data',
    //   width: '100',
    //   fillWidth: 1,
    //   header: 'test',
    //   styleName: 'text-left',
    //   renderer: {
    //     type: 'html',
    //     callback: function (grid, cell, w, h) {
    //       var str = '<button type="button" class="table-btn">등록</button>';
    //       console.log(grid);
    //       return str;
    //     },
    //   },
    // },
  ],
};

// 제로페이 제한업종조회 - 테이블
export const zpbLimBzctInitTableColumns = {
  fields: [{ fieldName: 'zpay_lim_bzct_cd' }, { fieldName: 'zpay_lim_bzct_nm' }],
  columns: [
    { fieldName: 'zpay_lim_bzct_cd', width: 100, fillWidth: 0, header: '업종코드' },
    { fieldName: 'zpay_lim_bzct_nm', width: 500, fillWidth: 0, header: '업종명', styleName: 'text-left' },
  ],
};

// 제로페이 결제계좌 거래내역조회 - 테이블
export const zpbStlActTrnPrtsInitTableColumns = {
  fields: [
    { fieldName: 'bk_cd' },
    { fieldName: 'act_no' },
    { fieldName: 'trn_his_dis' },
    { fieldName: 'trn_dt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'trn_srno' },
    { fieldName: 'trn_tm', dataType: 'datetime', datetimeFormat: 'hhmmss' },
    { fieldName: 'trn_des' },
    { fieldName: 'cur_cd' },
    { fieldName: 'trn_am', dataType: 'number' },
    {
      fieldName: 'trn_am1',
      dataType: 'number',
      valueCallback(prod, dataRow, fieldName, fieldNames, values) {
        var target = values[fieldNames.indexOf('trn_his_dis')];
        var returnVal = '';
        if (target === '02' || target === '2' || target === 2) {
          returnVal = values[fieldNames.indexOf('trn_am')];
        } else {
          returnVal = '0';
        }
        return Number(returnVal);
      },
    },
    {
      fieldName: 'trn_am2',
      dataType: 'number',
      valueCallback(prod, dataRow, fieldName, fieldNames, values) {
        var target = values[fieldNames.indexOf('trn_his_dis')];
        var returnVal = '';
        if (target === '01' || target === '1' || target === 1) {
          returnVal = values[fieldNames.indexOf('trn_am')];
        } else {
          returnVal = '0';
        }
        return Number(returnVal);
      },
    },
    { fieldName: 'bal_bl', dataType: 'number' },
    { fieldName: 'rcv_rqpe' },
    { fieldName: 'trbk_giro_cd' },
    { fieldName: 'obr_am' },
    { fieldName: 'acctUsgOtlnCd' },
    { fieldName: 'batDtlDis' },
  ],
  columns: [
    { fieldName: 'trn_dt', width: 100, fillWidth: 0, header: '거래일자', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'trn_des', width: 150, fillWidth: 0, header: '적요', styleName: 'text-left' },
    {
      fieldName: 'trn_am1',
      width: 50,
      fillWidth: 1,
      header: '지급금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'trn_am2',
      width: 50,
      fillWidth: 1,
      header: '입금금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'bal_bl',
      width: 50,
      fillWidth: 1,
      header: '거래후잔액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'trbk_giro_cd',
      width: 100,
      fillWidth: 0,
      header: '취급점',
    },
    { fieldName: 'trn_tm', width: 100, fillWidth: 0, header: '거래시간', datetimeFormat: 'hh:mm:ss' },
    { fieldName: 'rcv_rqpe', width: 170, fillWidth: 0, header: '입금의뢰인', styleName: 'text-left' },
  ],
};

// 제로페이 결제현황조회 - 구분 별 디테일 데이터
export const zpbStlPtsPeriodDetailOptions = [
  {
    id: 1,
    title: '기간내 총 결제금액',
    value: '0',
    unit: '원',
    isHigh: false,
  },
  {
    id: 2,
    title: '월 최고 결제금액',
    value: '0',
    unit: '원',
    isHigh: true,
  },
];

// 제로페이 결제현황조회 - 조회기간 별 차트옵션
export const zpbStlPtsChartOptions = {
  chart: {
    type: 'column',
    height: 312, // 레전드 포함 차트 전체 영역 높이
    marginBottom: 74, // 값에 따라 차트 높이 조절됨 (height - marginBottom - 10)
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      categories: [],
      crosshair: true,
    },
  ],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return Highcharts.numberFormat(this.value, 0, '', ','); // Add comma as the thousands separator
      },
    },
    // min: 1000000000,
    // max: 5000000000,
  },
  tooltip: {
    pointFormatter: function () {
      const { valueSuffix } = this.series.tooltipOptions;
      // Add comma as the thousands separator
      let tooltipText = `<b>${Highcharts.numberFormat(this.y, 0, ',', ',')}</b>${valueSuffix}<br/>`;
      if (this.series.type === 'column') {
        tooltipText += `<b>${Highcharts.numberFormat(this.additionalData, 0, ',', ',')}</b>${this.additionalSuffix}`;
      } else {
        tooltipText += `${
          this.biz_nm && this.biz_nm.trim()
            ? `<b>${this.biz_nm} | ${this.max_apv_dept_nm} | ${this.max_apv_user_id}</b>`
            : ''
        }`;
      }

      return tooltipText;
    },
    shared: false,
    headerFormat: '',
    // style
    borderRadius: 8,
    padding: 12,
    style: {
      fontSize: '14px',
      lineHeight: '20px', // Adjust the line spacing here
    },
  },
  colors: ['#99C1FF', '#263752'],
  series: [
    {
      dataLabels: '',
      name: '월 총 결제금액',
      type: 'column',
      data: [],
      tooltip: {
        valueSuffix: '원',
      },
    },
    {
      dataLabels: '',
      name: '월 최고결제 금액',
      type: 'spline',
      data: [],
      tooltip: {
        valueSuffix: '원',
      },
      lineWidth: 1,
      marker: {
        radius: 5, // point size
      },
    },
  ],
  legend: {
    layout: 'horizontal',
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 16,
    floating: true,
    itemDistance: 12, // legend 간 간격
    symbolPadding: 6,
    symbolWidth: 14, // line타입 심볼의 사이즈
    symbolHeight: 12, // 심볼 사이즈
  },
};

/**
 * 금고영업점
 */

// 금고영업점 - 지자체 정보 조회
export const brc0203v01InitTableColumns = {
  fields: [
    { fieldName: 'ntc_seq_no' },
    { fieldName: 'asw_dh' },
    { fieldName: 'asw_sts_cd' },
    { fieldName: 'asw_txt' },
    { fieldName: 'aswpe_id' },
    { fieldName: 'aswpe_nm' },
    { fieldName: 'cre_dh' },
    { fieldName: 'crepe_id' },
    { fieldName: 'crepe_nm' },
    { fieldName: 'ntc_titl' },
    { fieldName: 'ntc_txt' },
    { fieldName: 'best_yn' },
    { fieldName: 'cre_dh2', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'asw_dh2' },
    { fieldName: 'crepe_ist_nm' },
    { fieldName: 'crepe_dept_nm' },
    { fieldName: 'asw_dept_nm' },
    { fieldName: 'inqr_tpcd' },
    { fieldName: 'evl_sc' },
    { fieldName: 'ntc_txt_long' },
    { fieldName: 'br_cd' },
    { fieldName: 'cus_no' },
    { fieldName: 'lias_dt' },
    { fieldName: 'lias_psbr' },
    { fieldName: 'lias_psbr_nm' },
    { fieldName: 'lias_posi_nm' },
    { fieldName: 'lias_name_nm' },
    { fieldName: 'lias_opr_no' },
    { fieldName: 'lias_hq_brnm' },
    { fieldName: 'lias_hqmgr' },
    { fieldName: 'lias_brmr' },
    { fieldName: 'drfw_txt_long' },
    { fieldName: 'ist_kman_info_long' },
    { fieldName: 'ist_info_long' },
    { fieldName: 'uist_info_long' },
  ],
  columns: [
    {
      fieldName: 'ntc_titl',
      type: 'data',
      width: '400',
      fillWidth: 1,
      header: '제목',
      styleName: 'text-left',
    },
    {
      fieldName: 'crepe_nm',
      type: 'data',
      width: '68',
      fillWidth: 0,
      header: '등록자',
    },
    {
      fieldName: 'crepe_dept_nm',
      type: 'data',
      width: '125',
      fillWidth: 0,
      header: '등록지점',
    },
    {
      fieldName: 'cre_dh2',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '등록일',
      datetimeFormat: 'yyyy.MM.dd',
    },
    {
      fieldName: 'evl_sc',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '평가결과',
    },
  ],
};

// 금고영업점 - 공금업무 제안광장
export const brc0201p02InitTableColumns = {
  fields: [
    { fieldName: 'ntc_seq_no' },
    { fieldName: 'asw_dh' },
    { fieldName: 'asw_sts_cd' },
    { fieldName: 'asw_txt' },
    { fieldName: 'aswpe_id' },
    { fieldName: 'aswpe_nm' },
    { fieldName: 'cre_dh' },
    { fieldName: 'crepe_id' },
    { fieldName: 'crepe_nm' },
    { fieldName: 'ntc_titl' },
    { fieldName: 'ntc_txt' },
    { fieldName: 'ntc_txt_long' },
    { fieldName: 'best_yn' },
    { fieldName: 'cre_dh2', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'asw_dh2', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'crepe_ist_nm' },
    { fieldName: 'crepe_dept_nm' },
    { fieldName: 'asw_dept_nm' },
    { fieldName: 'inqr_tpcd' },
  ],
  columns: [
    { fieldName: 'ntc_titl', width: 250, fillWidth: 1, header: '제목', styleName: 'text-left' },
    { fieldName: 'crepe_nm', width: 80, fillWidth: 0, header: '등록자' },
    { fieldName: 'crepe_dept_nm', width: 120, fillWidth: 0, header: '등록지점' },
    { fieldName: 'cre_dh2', width: 100, fillWidth: 0, header: '등록일', datetimeFormat: 'yyyy.MM.dd' },
    {
      fieldName: 'aswpe_nm',
      width: 80,
      fillWidth: 0,
      header: '검토자',
    },
    {
      fieldName: 'asw_dept_nm',
      width: 120,
      fillWidth: 0,
      header: '검토지점',
    },
    { fieldName: 'asw_dh2', width: 100, fillWidth: 0, header: '검토일', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'asw_sts_cd', width: 80, fillWidth: 0, header: '답변상태' },
  ],
};

// 금고영업점 - 급여이체 점별 전체내역
export const brc0207v01InitTableColumns = {
  fields: [
    { fieldName: 'br_cd' },
    { fieldName: 'kbr_nm' },
    { fieldName: 'mts_trn_apv_no' },
    { fieldName: 'ist_nm' },
    { fieldName: 'mts_reqpe_bkw_acno' },
    { fieldName: 'mts_pype_no' },
    { fieldName: 'itcsno' },
    { fieldName: 'mts_prc_am_sum', dataType: 'number' },
    // { fieldName: 'prd_gat_utz_agr_yn' },
    // { fieldName: 'sms_mrkt_agr_yn' },
    { fieldName: 'cus_wrlc_nm' },
    { fieldName: 'siljuk_201912' },
  ],
  columns: [
    {
      fieldName: 'br_cd',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '점코드',
      footer: { text: '총계' },
    },
    {
      fieldName: 'kbr_nm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '지점명',
    },
    {
      fieldName: 'mts_trn_apv_no',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '의뢰번호',
    },
    {
      fieldName: 'ist_nm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '기관명',
    },
    {
      fieldName: 'mts_reqpe_bkw_acno',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '계좌번호',
    },
    {
      fieldName: 'mts_pype_no',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '성명',
      footer: { text: '' },
    },
    {
      fieldName: 'itcsno',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '통합고객번호',
      footer: {
        numberFormat: '#,##0',
        expression: 'count',
        styleName: 'text-right',
        suffix: ' 건',
      },
    },
    {
      fieldName: 'mts_prc_am_sum',
      type: 'data',
      width: '145',
      fillWidth: 0,
      header: '누적금액(1일~기준일)',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    // {
    //   fieldName: 'prd_gat_utz_agr_yn',
    //   type: 'data',
    //   width: '135',
    //   fillWidth: 0,
    //   header: '마켓팅동의여부',
    //   footer: { text: '' },
    // },
    // {
    //   fieldName: 'sms_mrkt_agr_yn',
    //   type: 'data',
    //   width: '135',
    //   fillWidth: 0,
    //   header: 'SMS동의여부',
    //   footer: { text: '' },
    // },
    {
      fieldName: 'cus_wrlc_nm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '고객직장명',
      footer: { text: '' },
    },
    {
      fieldName: 'siljuk_201912',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '2022.12 실적',
      footer: { text: '' },
      visible: false,
    },
  ],
  layout: [
    {
      name: 'ft_group',
      header: { visible: false },
      direction: 'horizontal',
      items: [{ column: 'br_cd', footerUserSpans: [{ colspan: 5 }] }],
    },
    'kbr_nm',
    'mts_trn_apv_no',
    'ist_nm',
    'mts_reqpe_bkw_acno',
    'mts_pype_no',
    'itcsno',
    'mts_prc_am_sum',
    // 'prd_gat_utz_agr_yn',
    // 'sms_mrkt_agr_yn',
    'cus_wrlc_nm',
    'siljuk_201912',
  ],
};

// 금고영업점 - 급여이체 전체내역
export const brc0207v02InitTableColumns = {
  fields: [
    { fieldName: 'br_cd' },
    { fieldName: 'kbr_nm' },
    { fieldName: 'mts_trn_apv_no' },
    { fieldName: 'ist_nm' },
    { fieldName: 'mts_reqpe_bkw_acno' },
    { fieldName: 'mts_pype_no' },
    { fieldName: 'itcsno' },
    { fieldName: 'mts_prc_am_sum', dataType: 'number' },
    // { fieldName: 'prd_gat_utz_agr_yn' },
    // { fieldName: 'sms_mrkt_agr_yn' },
    { fieldName: 'cus_wrlc_nm' },
    { fieldName: 'siljuk_201912' },
  ],
  columns: [
    {
      fieldName: 'br_cd',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '점코드',
      footer: { text: '총계' },
    },
    {
      fieldName: 'kbr_nm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '지점명',
    },
    {
      fieldName: 'mts_trn_apv_no',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '의뢰번호',
    },
    {
      fieldName: 'ist_nm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '기관명',
    },
    {
      fieldName: 'mts_reqpe_bkw_acno',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '계좌번호',
    },
    {
      fieldName: 'mts_pype_no',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '성명',
      footer: { text: '' },
    },
    {
      fieldName: 'itcsno',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '통합고객번호',
      footer: {
        numberFormat: '#,##0',
        expression: 'count',
        styleName: 'text-right',
        suffix: ' 건',
      },
    },
    {
      fieldName: 'mts_prc_am_sum',
      type: 'data',
      width: '145',
      fillWidth: 0,
      header: '누적금액(1일~기준일)',
      styleName: 'text-right',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    // {
    //   fieldName: 'prd_gat_utz_agr_yn',
    //   type: 'data',
    //   width: '135',
    //   fillWidth: 0,
    //   header: '마켓팅동의여부',
    //   footer: { text: '' },
    // },
    // {
    //   fieldName: 'sms_mrkt_agr_yn',
    //   type: 'data',
    //   width: '135',
    //   fillWidth: 0,
    //   header: 'SMS동의여부',
    //   footer: { text: '' },
    // },
    {
      fieldName: 'cus_wrlc_nm',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '고객직장명',
      footer: { text: '' },
    },
  ],
  layout: [
    {
      name: 'ft_group',
      header: { visible: false },
      direction: 'horizontal',
      items: [{ column: 'br_cd', footerUserSpans: [{ colspan: 5 }] }],
    },
    'kbr_nm',
    'mts_trn_apv_no',
    'ist_nm',
    'mts_reqpe_bkw_acno',
    'mts_pype_no',
    'itcsno',
    'mts_prc_am_sum',
    // 'prd_gat_utz_agr_yn',
    // 'sms_mrkt_agr_yn',
    'cus_wrlc_nm',
  ],
};

/**
 * 기타
 */

// 기타 보조금 수익성 분석 - 테이블
export const etcAsstPrbtAnlsInitTableColumns = {
  fields: [
    { fieldName: 'wfg_cd' },
    { fieldName: 'wk_bas_ym' },
    { fieldName: 'dacbr_cd' },
    { fieldName: 'synpb_prd_cd' },
    { fieldName: 'macc_sbj_cd' },
    { fieldName: 'act_no' },
    { fieldName: 'bzdiv_cd' },
    { fieldName: 'kbr_nm' },
    { fieldName: 'macc_sbj_nm' },
    { fieldName: 'prd_nm' },
    { fieldName: 'emn_bl' },
    { fieldName: 'dmn_avl_avgbl' },
    { fieldName: 'dmn_acm' },
    { fieldName: 'int_pft_am' },
    { fieldName: 'int_cost_am' },
    { fieldName: 'ntint_pft_am' },
    { fieldName: 'nint_pft_am' },
    { fieldName: 'fx_sbpft_am' },
    { fieldName: 'biz_pft_am' },
    { fieldName: 'acost_am' },
    { fieldName: 'fcst_los_am' },
    { fieldName: 'drtfe_am' },
    { fieldName: 'inmny_am' },
    { fieldName: 'rsk_adj_biz_pft_am' },
    { fieldName: 'ctb_pft_am' },
    { fieldName: 'rsk_adj_pft_am' },
    { fieldName: 'eco_pft_am' },
    { fieldName: 'etl_dt' },
    { fieldName: 'gip' },
    { fieldName: 'ct_act' },
    { fieldName: 'int1' },
    { fieldName: 'int2' },
    { fieldName: 'int3' },
    { fieldName: 'int4' },
    { fieldName: 'int5' },
  ],
  columns: [
    {
      fieldName: 'kbr_nm',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '영업점',
    },
    {
      fieldName: 'macc_sbj_cd',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '과목코드',
      footer: { text: '' },
    },
    {
      fieldName: 'macc_sbj_nm',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '과목명',
      footer: { text: '' },
    },
    {
      fieldName: 'synpb_prd_cd',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '종합상품코드',
      footer: { text: '' },
    },
    {
      fieldName: 'prd_nm',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '상품명',
      footer: { text: '총 합계' },
    },
    {
      fieldName: 'emn_bl',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '말잔',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'dmn_avl_avgbl',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '가용평잔',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'ct_act',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '계좌 수',
      styleName: 'text-right',
      footer: { text: '0' },
    },
    {
      fieldName: 'int_pft_am',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '금액',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'int1',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '율',
      styleName: 'text-right',
      footer: { text: '0.00' },
    },

    {
      fieldName: 'int_cost_am',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '금액',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'int2',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '율',
      styleName: 'text-right',
      footer: { text: '0.00' },
    },

    {
      fieldName: 'ntint_pft_am',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '금액',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'int3',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '율',
      styleName: 'text-right',
      footer: { text: '0.00' },
    },
    {
      fieldName: 'nint_pft_am',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '비이자수익(D)',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'fx_sbpft_am',
      type: 'data',
      width: '90',
      fillWidth: 0,
      header: '(외환매매익)',
      styleName: 'text-right',
      footer: { text: '0.00' },
    },
    {
      fieldName: 'biz_pft_am',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '영업수익\n(E=C+D)',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'acost_am',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '부대비용(G)',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'fcst_los_am',
      type: 'data',
      width: '90',
      fillWidth: 0,
      header: { text: '예상/발생\n손실(H)', styleName: 'white-space' },
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'drtfe_am',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: { text: '업무원가\n직접비(I)', styleName: 'white-space' },
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'inmny_am',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: { text: '업무원가\n간접비(J)', styleName: 'white-space' },
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'rsk_adj_biz_pft_am',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '금액',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'int4',
      type: 'data',
      width: '60',
      fillWidth: 0,
      header: '율',
      styleName: 'text-right',
      footer: { text: '0.00' },
    },
    {
      fieldName: 'ctb_pft_am',
      type: 'data',
      width: '95',
      fillWidth: 0,
      header: '공헌수익\n(L=E-G-H-I)',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'rsk_adj_pft_am',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '금액',
      styleName: 'text-right',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'int5',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '율',
      styleName: 'text-right',
      footer: { text: '0.00' },
    },
  ],
  layout: [
    'kbr_nm',
    'macc_sbj_cd',
    'macc_sbj_nm',
    'synpb_prd_cd',
    'prd_nm',
    'emn_bl',
    'dmn_avl_avgbl',
    'ct_act',

    {
      name: 'companyGroup1',
      direction: 'horizontal',
      items: ['int_pft_am', 'int1'],
      header: {
        text: '이자수익(A)',
      },
    },

    {
      name: 'companyGroup1',
      direction: 'horizontal',
      items: ['int_cost_am', 'int2'],
      header: {
        text: '이자비용(B)',
      },
    },
    {
      name: 'companyGroup1',
      direction: 'horizontal',
      items: ['ntint_pft_am', 'int3'],
      header: {
        text: '순이자이익(C=A-B)',
      },
    },
    {
      name: 'companyGroup1',
      direction: 'horizontal',
      items: ['nint_pft_am', 'fx_sbpft_am'],
      header: {
        text: '부대수익',
      },
    },
    'biz_pft_am',
    {
      name: 'companyGroup1',
      direction: 'horizontal',
      items: ['acost_am', 'fcst_los_am', 'drtfe_am', 'inmny_am'],
      header: {
        text: '영업비용(N)',
      },
    },
    {
      name: 'companyGroup1',
      direction: 'horizontal',
      items: ['rsk_adj_biz_pft_am', 'int4'],
      header: {
        text: '위험조정영업수익\n(K=E-G-H)',
        styleName: 'white-space',
      },
    },
    'ctb_pft_am',
    {
      name: 'companyGroup1',
      direction: 'horizontal',
      items: ['rsk_adj_pft_am', 'int5'],
      header: {
        text: '위험조정이익\n(M=E-N)',
        styleName: 'white-space',
      },
    },
  ],
};

// 기타 보조금 수익성 추세 - 테이블
export const etcAsstPrbtTndcInitTableColumns = {
  fields: [
    { fieldName: 'kbr_nm' },
    { fieldName: 'macc_sbj_cd' },
    { fieldName: 'macc_sbj_nm' },
    { fieldName: 'dacbr_cd' },
    { fieldName: 'synpb_prd_cd' },
    { fieldName: 'prd_nm' },
    { fieldName: 'amt_1', dataType: 'number' },
    { fieldName: 'amt_2', dataType: 'number' },
    { fieldName: 'amt_3', dataType: 'number' },
    { fieldName: 'amt_4', dataType: 'number' },
    { fieldName: 'amt_5', dataType: 'number' },
    { fieldName: 'amt_6', dataType: 'number' },
    { fieldName: 'amt_7', dataType: 'number' },
    { fieldName: 'amt_8', dataType: 'number' },
    { fieldName: 'amt_9', dataType: 'number' },
    { fieldName: 'amt_10', dataType: 'number' },
    { fieldName: 'amt_11', dataType: 'number' },
    { fieldName: 'amt_12', dataType: 'number' },
    {
      fieldName: 'amt_sum',
      dataType: 'number',
      valueCallback(prod, dataRow, fieldName, fieldNames, values) {
        const amt_sum =
          Number(values[fieldNames.indexOf('amt_1')]) +
          Number(values[fieldNames.indexOf('amt_2')]) +
          Number(values[fieldNames.indexOf('amt_3')]) +
          Number(values[fieldNames.indexOf('amt_4')]) +
          Number(values[fieldNames.indexOf('amt_5')]) +
          Number(values[fieldNames.indexOf('amt_6')]) +
          Number(values[fieldNames.indexOf('amt_7')]) +
          Number(values[fieldNames.indexOf('amt_8')]) +
          Number(values[fieldNames.indexOf('amt_9')]) +
          Number(values[fieldNames.indexOf('amt_10')]) +
          Number(values[fieldNames.indexOf('amt_11')]) +
          Number(values[fieldNames.indexOf('amt_12')]);
        return amt_sum;
      },
    },
  ],
  columns: [
    {
      fieldName: 'kbr_nm',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '영업점',
    },
    {
      fieldName: 'macc_sbj_cd',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '과목코드',
    },
    {
      fieldName: 'macc_sbj_nm',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '과목명',
    },
    {
      fieldName: 'prd_nm',
      type: 'data',
      width: '180',
      fillWidth: 0,
      header: '상품명',
    },
    {
      fieldName: 'amt_1',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '1월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_2',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '2월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_3',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '3월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_4',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '4월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_5',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '5월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_6',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '6월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_7',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '7월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_8',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '8월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_9',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '9월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_10',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '10월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_11',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '11월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_12',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '12월',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'amt_sum',
      width: '120',
      fillWidth: 0,
      header: '합계',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
  ],
};

// 기타 보조금 좌수 현황 - 테이블
export const etcAsstAtcntPtsInitTableColumns = {
  fields: [
    { fieldName: 'act_mngbr_cd' },
    { fieldName: 'kbr_nm' },
    { fieldName: 'lastSum' },
    { fieldName: 'cni_rst_cn01' },
    { fieldName: 'cni_rst_cn02' },
    { fieldName: 'cni_rst_cn03' },
    { fieldName: 'cni_rst_cn04' },
    { fieldName: 'cni_rst_cn05' },
    { fieldName: 'cni_rst_cn06' },
    { fieldName: 'halfsum' },
    { fieldName: 'cni_rst_cn07' },
    { fieldName: 'cni_rst_cn08' },
    { fieldName: 'cni_rst_cn09' },
    { fieldName: 'cni_rst_cn10' },
    { fieldName: 'cni_rst_cn11' },
    { fieldName: 'cni_rst_cn12' },
    { fieldName: 'allsum' },
    { fieldName: 'plusminus' },
    { fieldName: 'bas_dt' },
  ],
  columns: [
    {
      fieldName: 'kbr_nm',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '영업점',
    },
    {
      fieldName: 'lastSum',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: { text: '전년도 7월~12월', styleName: 'white-space' },
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn01',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '1월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn02',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '2월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn03',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '3월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn04',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '4월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn05',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '5월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn06',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '6월',
      styleName: 'text-right',
    },
    {
      fieldName: 'halfSum',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '반기 합계',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn07',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '7월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn08',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '8월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn09',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '9월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn10',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '10월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn11',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '11월',
      styleName: 'text-right',
    },
    {
      fieldName: 'cni_rst_cn12',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '12월',
      styleName: 'text-right',
    },
    {
      fieldName: 'allSum',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '합계',
      styleName: 'text-right',
    },
    {
      fieldName: 'plusMinus',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '반기대비 증 감',
      styleName: 'text-right',
    },
  ],
};

export const etcAsstBznAccnBgtBssnRtInitTableColumns = {
  fields: [
    { fieldName: 'ist_nm1' },
    { fieldName: 'actCnt' },
    { fieldName: 'rvc_am11' },
    //{ fieldName: 'rcv_am11' },
    { fieldName: 'tot_rcv_am1' },
    { fieldName: 'per1' },
    { fieldName: 'rcv_am1' },
    { fieldName: 'tot_rcv_am' },
    { fieldName: 'per2' },
    { fieldName: 'per3' },
  ],
  columns: [
    {
      fieldName: 'ist_nm1',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '지점명',
      footer: { text: '총계' },
    },
    {
      fieldName: 'actCnt',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '계좌 수',
      footer: { text: '0', styleName: 'text-right' },
    },
    {
      fieldName: 'rvc_am11',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '당행유치',
      styleName: 'text-right',
      footer: { text: '0,000,000,000', styleName: 'text-right' },
    },
    {
      fieldName: 'tot_rcv_am1',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '보조금지출예산',
      styleName: 'text-right',
      footer: { text: '0,000,000,000', styleName: 'text-right' },
    },
    {
      fieldName: 'per1',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '비율 ⓐ',
      styleName: 'text-right',
      footer: { text: '00.00%', styleName: 'text-right' },
    },
    {
      fieldName: 'rcv_am1',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '당행유치',
      styleName: 'text-right',
      footer: { text: '0,000,000,000', styleName: 'text-right' },
    },
    {
      fieldName: 'tot_rcv_am',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '보조금지출예산',
      styleName: 'text-right',
      footer: { text: '0,000,000,000', styleName: 'text-right' },
    },
    {
      fieldName: 'per2',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '비율 ⓑ',
      styleName: 'text-right',
      footer: { text: '00.00%', styleName: 'text-right' },
    },
    {
      fieldName: 'per3',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '점유율 ⓑ - ⓐ',
      styleName: 'text-right',
      footer: { text: '00.00%', styleName: 'text-right' },
    },
  ],
  layout: [
    'ist_nm1',
    'actCnt',

    {
      name: 'group1',
      direction: 'horizontal',
      items: ['rvc_am11', 'tot_rcv_am1', 'per1'],
      header: {
        text: '',
      },
    },

    {
      name: 'group2',
      direction: 'horizontal',
      items: ['rcv_am1', 'tot_rcv_am', 'per2'],
      header: {
        text: '',
      },
    },
    'per3',
  ],
};

export const etcAsstBznAccnBgtBssnRtInitTableColumns2 = {
  fields: [
    { fieldName: 'ist_nm1' },
    { fieldName: 'actCnt' },
    // { fieldName: 'rvc_am11' },
    { fieldName: 'rcv_am11' },
    { fieldName: 'tot_rcv_am1' },
    { fieldName: 'per1' },
    { fieldName: 'rcv_am1' },
    { fieldName: 'tot_rcv_am' },
    { fieldName: 'per2' },
    { fieldName: 'per3' },
  ],
  columns: [
    {
      fieldName: 'ist_nm1',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '지점명',
      footer: { text: '총계' },
    },
    {
      fieldName: 'actCnt',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '계좌 수',
      footer: { text: '0', styleName: 'text-right' },
    },
    /**  */
    // {
    //   fieldName: 'rvc_am11',
    //   type: 'data',
    //   width: '180',
    //   fillWidth: 0,
    //   header: '당행유치1',
    //   styleName: 'text-right',
    //   footer: { text: '0,000,000,000', styleName: 'text-right' },
    // },
    {
      fieldName: 'rcv_am11',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '당행유치',
      styleName: 'text-right',
      footer: { text: '0,000,000,000', styleName: 'text-right' },
    },
    {
      fieldName: 'tot_rcv_am1',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '보조금지출예산',
      styleName: 'text-right',
      footer: { text: '0,000,000,000', styleName: 'text-right' },
    },
    {
      fieldName: 'per1',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '비율 ⓐ',
      styleName: 'text-right',
      footer: { text: '00.00%', styleName: 'text-right' },
    },
    {
      fieldName: 'rcv_am1',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '당행유치',
      styleName: 'text-right',
      footer: { text: '0,000,000,000', styleName: 'text-right' },
    },
    {
      fieldName: 'tot_rcv_am',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '보조금지출예산',
      styleName: 'text-right',
      footer: { text: '0,000,000,000', styleName: 'text-right' },
    },
    {
      fieldName: 'per2',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '비율 ⓑ',
      styleName: 'text-right',
      footer: { text: '00.00%', styleName: 'text-right' },
    },
    {
      fieldName: 'per3',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '점유율 ⓑ - ⓐ',
      styleName: 'text-right',
      footer: { text: '00.00%', styleName: 'text-right' },
    },
  ],
  layout: [
    'ist_nm1',
    'actCnt',

    {
      name: 'group1',
      direction: 'horizontal',
      items: ['rcv_am11', 'tot_rcv_am1', 'per1'],
      header: {
        text: '',
      },
    },

    {
      name: 'group2',
      direction: 'horizontal',
      items: ['rcv_am1', 'tot_rcv_am', 'per2'],
      header: {
        text: '',
      },
    },
    'per3',
  ],
};
// 2023-11-13 ~ 2023-11-13
// 기타 자금이체내역표 - 테이블
export const etc0101m01InitTableColumns = {
  fields: [
    { fieldName: 'seipdate', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'sunapgbn' },
    { fieldName: 'sunapdate' },
    { fieldName: 'seiphg' },
    { fieldName: 'hgname' },
    { fieldName: 'ilcount', dataType: 'number' },
    { fieldName: 'sunapamt', dataType: 'number' },
    { fieldName: 'inq_seg' },
    { fieldName: 'inq_dt' },
    { fieldName: 'iche_gbn' },
  ],
  filters: true,
  columns: [
    {
      fieldName: 'seipdate',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '이체일자',
      datetimeFormat: 'yyyy.MM.dd',
      footer: { text: '합계' },
      editable: false,
    },
    {
      fieldName: 'sunapgbn',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '세입구분',
      editable: false,
    },
    {
      fieldName: 'sunapdate',
      type: 'data',
      width: '120',
      fillWidth: 1,
      header: '수납기간',
      editable: false,
    },

    {
      fieldName: 'hgname',
      type: 'data',
      width: '120',
      fillWidth: 1,
      header: '회계명',
      editable: false,
    },

    {
      fieldName: 'seiphg',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '회계코드',
      editable: false,
    },

    {
      fieldName: 'ilcount',
      type: 'data',
      width: '90',
      fillWidth: 0,
      styleName: 'text-right',
      header: { text: '전송 건수', styleName: ' bg-color01' },
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
      editable: false,
    },
    {
      fieldName: 'sunapamt',
      type: 'data',
      width: '200',
      fillWidth: 0,

      styleName: 'text-right',
      header: { text: '전송 금액', styleName: ' bg-color01' },
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
      editable: false,
    },
  ],
  layout: [
    {
      name: 'companyGroup1',
      header: {
        visible: false,
      },
      direction: 'horizontal',
      items: [{ column: 'seipdate', footerUserSpans: [{ colspan: 5 }] }],
    },
    'sunapgbn',
    'sunapdate',
    'hgname',
    'seiphg',
    'ilcount',
    'sunapamt',
  ],
};

// 기타 보통예금 계좌 거래내역 조회 - 테이블
export const etc0201v01InitTableColumns = {
  fields: [
    { fieldName: 'column01' },
    { fieldName: 'column02' },
    { fieldName: 'column03' },
    { fieldName: 'column04' },
    { fieldName: 'column05' },
    { fieldName: 'column06' },
    { fieldName: 'column07' },
    { fieldName: 'column08' },
  ],
  columns: [
    {
      fieldName: 'column01',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '거래일자',
    },
    {
      fieldName: 'column02',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '적요',
    },
    {
      fieldName: 'column03',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '지급금액',
      styleName: 'text-right',
    },

    {
      fieldName: 'column04',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '입금금액',
      styleName: 'text-right',
    },

    {
      fieldName: 'column05',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '거래후잔액',
      styleName: 'text-right',
    },

    {
      fieldName: 'column06',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '취급점',
    },
    {
      fieldName: 'column07',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '거래시간',
    },
    {
      fieldName: 'column08',
      type: 'data',
      width: '200',
      fillWidth: 1,
      header: '입금의뢰인',
    },
  ],
  rows: [
    {
      column01: 'YYYY-MM-DD',
      column02: '예금결산이자',
      column03: '0',
      column04: '0',
      column05: '0',
      column06: '000000',
      column07: '00:00:00',
      column08: '관리구좌집금',
    },
  ],
};

// 기타 공금계좌 집계조회 - 테이블
export const etc0202v01InitTableColumns = {
  fields: [
    { fieldName: 'act_no' },
    { fieldName: 'tax_rcvwd_trn_dscd' },
    { fieldName: 'tax_rcvwd_trn_nm' },
    { fieldName: 'trn_dt' },
    { fieldName: 'rcv_am', dataType: 'number' },
    { fieldName: 'rvcan_am', dataType: 'number' },
    { fieldName: 'pay_am', dataType: 'number' },
    { fieldName: 'pycan_am', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'trn_dt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '거래일',
    },
    {
      fieldName: 'tax_rcvwd_trn_nm',
      type: 'data',
      width: '180',
      fillWidth: 1,
      header: '구분명',
    },
    {
      fieldName: 'rcv_am',
      // type: 'data',
      width: '160',
      fillWidth: 0,
      header: '입금액',
      styleName: 'number-column text-right',
      numberFormat: '#,##0',
    },

    {
      fieldName: 'rvcan_am',
      // type: 'data',
      width: '160',
      fillWidth: 0,
      header: '입금취소액',
      styleName: 'number-column text-right',
      numberFormat: '#,##0',
    },

    {
      fieldName: 'pay_am',
      // type: 'data',
      width: '160',
      fillWidth: 0,
      header: '지급액',
      styleName: 'number-column text-right',
      numberFormat: '#,##0',
    },

    {
      fieldName: 'pycan_am',
      // type: 'data',
      width: '160',
      fillWidth: 0,
      header: '지급취소액',
      styleName: 'number-column text-right',
      numberFormat: '#,##0',
    },
  ],
};

// 기타 서울페이플러스 > 계좌조회 - 테이블
export const etcActInqInitTableColumns = {
  fields: [
    { fieldName: 'column01' },
    { fieldName: 'column02' },
    { fieldName: 'column03' },
    { fieldName: 'column04' },
    { fieldName: 'column05' },
    { fieldName: 'column06' },
    { fieldName: 'column07' },
  ],
  columns: [
    {
      fieldName: 'column01',
      type: 'data',
      width: '210',
      fillWidth: 0,
      header: '판매구분',
    },
    {
      fieldName: 'column02',
      type: 'data',
      width: '210',
      fillWidth: 0,
      header: '계좌번호',
    },
    {
      fieldName: 'column03',
      type: 'data',
      width: '210',
      fillWidth: 0,
      header: '기관구분',
    },
    {
      fieldName: 'column04',
      type: 'data',
      width: '300',
      fillWidth: 0,
      header: '상품권명',
    },
    {
      fieldName: 'column05',
      type: 'data',
      width: '210',
      fillWidth: 0,
      header: '등록자(행번)',
    },
    {
      fieldName: 'column06',
      type: 'data',
      width: '210',
      fillWidth: 0,
      header: '등록일',
    },
    {
      fieldName: 'column07',
      type: 'data',
      width: '210',
      fillWidth: 0,
      header: '사용여부',
    },
  ],
  rows: [
    {
      column01: '정책금융상품권',
      column02: '0000-000-000000',
      column03: '강동구청',
      column04: '자치행정과고향사랑',
      column05: '00000000',
      column06: 'YYYY-MM-DD',
      column07: 'Y',
    },
    {
      column01: '정책금융상품권',
      column02: '0000-000-000000',
      column03: '강동구청',
      column04: '자치행정과고향사랑',
      column05: '00000000',
      column06: 'YYYY-MM-DD',
      column07: 'Y',
    },
    {
      column01: '정책금융상품권',
      column02: '0000-000-000000',
      column03: '강동구청',
      column04: '자치행정과고향사랑',
      column05: '00000000',
      column06: 'YYYY-MM-DD',
      column07: 'Y',
    },
    {
      column01: '정책금융상품권',
      column02: '0000-000-000000',
      column03: '강동구청',
      column04: '자치행정과고향사랑',
      column05: '00000000',
      column06: 'YYYY-MM-DD',
      column07: 'Y',
    },
    {
      column01: '정책금융상품권',
      column02: '0000-000-000000',
      column03: '강동구청',
      column04: '자치행정과고향사랑',
      column05: '00000000',
      column06: 'YYYY-MM-DD',
      column07: 'Y',
    },
    {
      column01: '정책금융상품권',
      column02: '0000-000-000000',
      column03: '강동구청',
      column04: '자치행정과고향사랑',
      column05: '00000000',
      column06: 'YYYY-MM-DD',
      column07: 'Y',
    },
  ],
};

// 기타 서울페이플러스 > 집계내역조회 - 테이블
export const etc0402v01InitTableColumns = {
  fields: [
    { fieldName: 'act_no' },
    { fieldName: 'ist_cd' },
    { fieldName: 'bne_c' },
    { fieldName: 'tg_seqnum' },
    { fieldName: 'atn' },
    { fieldName: 'ts_dt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'psn_gfa_pha_at', dataType: 'number' },
    { fieldName: 'psn_gfa_pha_cla', dataType: 'number' },
    { fieldName: 'crp_gfa_pha_at', dataType: 'number' },
    { fieldName: 'crp_gfa_pha_cla', dataType: 'number' },
    { fieldName: 'rwrd_gfa_acm_at', dataType: 'number' },
    { fieldName: 'rwrd_gfa_acm_cla', dataType: 'number' },
    { fieldName: 'psn_fd_at', dataType: 'number' },
    { fieldName: 'saa', dataType: 'number' },
    { fieldName: 'sls_cla', dataType: 'number' },
    { fieldName: 'tc_rca', dataType: 'number' },
    { fieldName: 'rcp_to_at', dataType: 'number' },
    { fieldName: 'aow_to_at', dataType: 'number' },
    { fieldName: 'db_chg_ts' },
    { fieldName: 'rmk_txt' },
    { fieldName: 'pay_amt', dataType: 'number' },
    { fieldName: 'cha_amt', dataType: 'number' },
    { fieldName: 'trn_am', dataType: 'number' },
    { fieldName: 'cha_amt2', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'ist_cd',
      type: 'data',
      width: '90',
      fillWidth: 0,
      header: '기관명',
      footer: { text: '' },
    },
    {
      fieldName: 'act_no',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '계좌번호',
      textFormat: '([0-9]{4})([0-9]{3})([0-9]{6});$1-$2-$3',
      footer: { text: '합계' },
    },
    {
      fieldName: 'ts_dt',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '거래일자',
      datetimeFormat: 'yyyy.MM.dd',

      footer: { text: '' },
    },
    {
      fieldName: 'rmk_txt',
      type: 'data',
      width: '250',
      fillWidth: 0,
      header: '상품권명',

      footer: { text: '' },
    },
    {
      fieldName: 'psn_gfa_pha_at',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '개인상품권\n구매금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'psn_gfa_pha_cla',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '개인상품권\n구매취소금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'crp_gfa_pha_at',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '법인상품권\n구매금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'crp_gfa_pha_cla',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '법인상품권\n구매취소금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'rwrd_gfa_acm_at',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '리워드상품권\n구매금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'rwrd_gfa_acm_cla',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '리워드상품권\n구매취소금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'psn_fd_at',
      dataType: 'number',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '개인환불금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'saa',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '매출금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'sls_cla',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '매출취소금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tc_rca',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '이관입금금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'rcp_to_at',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '입금총금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'trn_am',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '실입금총액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'cha_amt2',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '임금차액', styleName: ' bg-color01' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'aow_to_at',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '출금총금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'pay_amt',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '실지급총액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'cha_amt',
      dataType: 'number',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '지급차액', styleName: ' bg-color01' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
  ],
};

// 기타 서울페이플러스 > 계좌거래명세 - 테이블
export const etc0403v01InitTableColumns = {
  fields: [
    { fieldName: 'bk_cd' },
    { fieldName: 'act_no' },
    { fieldName: 'trn_his_dis' },
    { fieldName: 'trn_dt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'trn_srno' },
    { fieldName: 'trn_tm', dataType: 'datetime', datetimeFormat: 'hhmmss' },
    { fieldName: 'gb' },
    { fieldName: 'cur_cd' },
    { fieldName: 'trn_am' },
    {
      fieldName: 'trn_am1',
      dataType: 'number',
      valueCallback(prod, dataRow, fieldName, fieldNames, values) {
        var target = values[fieldNames.indexOf('trn_his_dis')];
        var returnVal = '';
        if (target === '02' || target === '2' || target === 2) {
          returnVal = values[fieldNames.indexOf('trn_am')];
        } else {
          returnVal = '0';
        }
        return Number(returnVal);
      },
    },
    {
      fieldName: 'trn_am2',
      dataType: 'number',
      valueCallback(prod, dataRow, fieldName, fieldNames, values) {
        var target = values[fieldNames.indexOf('trn_his_dis')];
        var returnVal = '';
        if (target === '01' || target === '1' || target === 1) {
          returnVal = values[fieldNames.indexOf('trn_am')];
        } else {
          returnVal = '0';
        }
        return Number(returnVal);
      },
    },
    { fieldName: 'bal_bl', dataType: 'number' },
    { fieldName: 'rcv_rqpe' },
    { fieldName: 'trbk_giro_cd' },
    { fieldName: 'obr_am' },
    { fieldName: 'acctUsgOtlnCd' },
    { fieldName: 'batDtlDis' },
  ],
  columns: [
    { fieldName: 'trn_dt', width: 110, fillWidth: 0, header: '거래일자', datetimeFormat: 'yyyy.MM.dd' },
    { fieldName: 'gb', width: 120, fillWidth: 0, header: '적요', styleName: 'text-left' },
    {
      fieldName: 'trn_am1',
      width: 140,
      fillWidth: 0,
      header: '지급금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'trn_am2',
      width: 140,
      fillWidth: 0,
      header: '입금금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'bal_bl',
      width: 140,
      fillWidth: 0,
      header: '거래후잔액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'trbk_giro_cd',
      width: 110,
      fillWidth: 0,
      header: '취급점',
    },
    { fieldName: 'trn_tm', width: 100, fillWidth: 0, header: '거래시간', datetimeFormat: 'hh:mm:ss' },
    { fieldName: 'rcv_rqpe', width: 140, fillWidth: 0, header: '입금의뢰인', styleName: 'text-left' },
  ],
};

// 기타 서울페이플러스 > 처리내역조회 - 테이블
export const etc0404v01InitTableColumns = {
  fields: [
    { fieldName: 'ts_dt' },
    { fieldName: 'tg_seqnum' },
    { fieldName: 'dep_sqno' },
    { fieldName: 'tot_amt' },
    { fieldName: 'aow_bcd' },
    { fieldName: 'aow_mti_if' },
    { fieldName: 'old_aow_mti_if' },
    { fieldName: 'aow_abr' },
    { fieldName: 'rcp_bcd' },
    { fieldName: 'rcp_mti_if' },
    { fieldName: 'rcp_abr' },
    { fieldName: 'dep_cnt' },
    { fieldName: 'bt_am' },
    { fieldName: 'ts_prc_stcd' },
    { fieldName: 'ts_rst_cd' },
    { fieldName: 'ts_rst_dps_dh', dataType: 'datetime', datetimeFormat: 'yyyyMMddhhmmss' },
    { fieldName: 'err_loc' },
    { fieldName: 'err_msg' },
    { fieldName: 'paybr_cd' },
    { fieldName: 'opbr_cd' },
    { fieldName: 'unq_no' },
    { fieldName: 'db_chg_ts' },
    { fieldName: 'rmk_txt' },
    { fieldName: 'kbr_nm' },
    { fieldName: 'chk_yn_flag' },
    { fieldName: 'trn_no' },
    { fieldName: 'chk_yn_flag2' },
    { fieldName: 'sum1' },
    { fieldName: 'sum2' },
    { fieldName: 'sum3' },
    { fieldName: 'cha_amt' },
  ],
  columns: [
    {
      fieldName: 'tg_seqnum',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '일련번호',
    },
    {
      fieldName: 'dep_sqno',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '입금회차',
    },
    {
      fieldName: 'tot_amt',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '총금액',
      styleName: 'text-right',
    },
    {
      fieldName: 'paybr_cd',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '취급점',
    },
    {
      fieldName: 'aow_mti_if',
      type: 'data',
      width: '180',
      fillWidth: 0,
      header: '출금계좌',
    },
    {
      fieldName: 'rcp_mti_if',
      type: 'data',
      width: '180',
      fillWidth: 0,
      header: '입금계좌',
    },
    {
      fieldName: 'bt_am',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '이체금액',
      styleName: 'text-right',
    },
    {
      fieldName: 'ts_rst_cd',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '이체결과코드',
    },
    {
      fieldName: 'ts_rst_dps_dh',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '이체결과일시',
      datetimeFormat: 'yyyy.MM.dd hh:mm:ss',
    },
    {
      fieldName: 'opbr_cd',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '에러코드',
    },
    {
      fieldName: 'err_msg',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '에러메시지',
    },
  ],
};

// 기타 서울페이플러스 > 처리내역조회(관리자) - 서브테이블
export const etc0405m01InitDetail = [
  {
    id: 1,
    children: [
      {
        id: 'sum1',
        title: '파일금액',
        text: '',
        unit: '원',
        isNum: true,
      },
    ],
  },
  {
    id: 2,
    children: [
      {
        id: 'sum2',
        title: '출금요청금액',
        text: '',
        unit: '원',
        isNum: true,
      },
    ],
  },
  {
    id: 3,
    children: [
      {
        id: 'sum3',
        title: '이체완료금액',
        text: '',
        unit: '원',
        isNum: true,
      },
    ],
  },
  {
    id: 4,
    children: [
      {
        id: 'cha_amt',
        title: '차액',
        text: '',
        unit: '원',
        styleName: 'text-right text-red',
        isNum: true,
      },
    ],
  },
];

// 기타 서울페이플러스 > 처리내역조회(관리자) - 테이블
export const etc0405m01InitTableColumns = {
  fields: [
    { fieldName: 'ts_dt' },
    { fieldName: 'tg_seqnum' },
    { fieldName: 'dep_sqno' },
    { fieldName: 'tot_amt' },
    { fieldName: 'aow_bcd' },
    { fieldName: 'aow_mti_if' },
    { fieldName: 'old_aow_mti_if' },
    { fieldName: 'aow_abr' },
    { fieldName: 'rcp_bcd' },
    { fieldName: 'rcp_mti_if' },
    { fieldName: 'rcp_abr' },
    { fieldName: 'dep_cnt' },
    { fieldName: 'bt_am' },
    { fieldName: 'ts_prc_stcd' },
    { fieldName: 'ts_rst_cd' },
    { fieldName: 'ts_rst_dps_dh', dataType: 'datetime', datetimeFormat: 'yyyyMMddhhmmss' },
    { fieldName: 'err_loc' },
    { fieldName: 'err_msg' },
    { fieldName: 'paybr_cd' },
    { fieldName: 'opbr_cd' },
    { fieldName: 'unq_no' },
    { fieldName: 'db_chg_ts' },
    { fieldName: 'rmk_txt' },
    { fieldName: 'kbr_nm' },
    { fieldName: 'chk_yn_flag' },
    { fieldName: 'trn_no' },
    { fieldName: 'chk_yn_flag2' },
    { fieldName: 'sum1' },
    { fieldName: 'sum2' },
    { fieldName: 'sum3' },
    { fieldName: 'cha_amt' },
  ],
  columns: [
    {
      fieldName: 'tg_seqnum',
      width: 110,
      fillWidth: 0,
      header: '일련번호',
      mergeRule: {
        criteria: 'value',
      },
    },
    { fieldName: 'dep_sqno', width: 80, fillWidth: 0, header: '입금회차' },
    { fieldName: 'tot_amt', width: 110, fillWidth: 0, header: '총금액', styleName: 'text-right' },
    { fieldName: 'paybr_cd', width: 120, fillWidth: 0, header: '취급점' },
    { fieldName: 'aow_mti_if', width: 160, fillWidth: 0, header: '출금계좌' },
    { fieldName: 'rcp_mti_if', width: 160, fillWidth: 0, header: '입금계좌' },
    { fieldName: 'bt_am', width: 130, fillWidth: 0, header: '이체금액', styleName: 'text-right' },
    { fieldName: 'ts_rst_cd', width: 100, fillWidth: 0, header: '이체결과코드' },
    {
      fieldName: 'ts_rst_dps_dh',
      width: 140,
      fillWidth: 0,
      header: '이체결과일시',
      datetimeFormat: 'yyyy.MM.dd hh:mm:ss',
    },
    { fieldName: 'opbr_cd', width: 120, fillWidth: 0, header: '에러코드' },
    { fieldName: 'err_msg', width: 160, fillWidth: 0, header: '에러메시지', styleName: 'text-left text-red' },
    { fieldName: 'unq_no', width: 120, fillWidth: 1, header: '거래고유번호' },
    {
      fieldName: 'chk_yn_flag2',
      width: 100,
      fillWidth: 0,
      header: { text: '지급처리', styleName: 'text-red' },
      // renderer: {
      //   type: 'html',
      //   callback: function (grid, cell, w, h) {
      //     var str = '<button type="button" class="table-btn">등록</button>';
      //     return str;
      //   },
      // },
    },
    {
      fieldName: 'chk_yn_flag',
      width: 90,
      fillWidth: 0,
      header: { text: '이체완료처리', styleName: 'text-red' },
      // renderer: {
      //   type: 'html',
      //   callback: function (grid, cell, w, h) {
      //     var str = '<button type="button" class="table-btn">저장</button>';
      //     return str;
      //   },
      // },
    },
    { fieldName: 'trn_no', width: 100, fillWidth: 0, header: { text: '상태변경자', styleName: 'text-blue' } },
  ],
};

// 기타 서울페이플러스 > 자금집계현황 - 테이블(신규)
export const etc0406v01InitTableColumnsNew = {
  fields: [
    { fieldName: 'act_no' } /* 계좌번호 */,
    { fieldName: 'ist_cd' } /* 기관명 */,
    { fieldName: 'atn' },
    { fieldName: 'rmk_txt' } /* 상품권명 */,
    { fieldName: 'psn_act_pur', dataType: 'number' } /*개인계좌구매*/,
    { fieldName: 'psn_cad_pur', dataType: 'number' } /** 개인카드구매 */,
    { fieldName: 'corp_pur', dataType: 'number' } /** 법인구매 */,
    { fieldName: 'col_am', dataType: 'number' } /**QR추심   */,
    { fieldName: 'plcy_issu', dataType: 'number' } /**정책발행 */,
    { fieldName: 'dc_plc_prn', dataType: 'number' } /**할인지원금   */,
    { fieldName: 'spcl_act_psn_rprf', dataType: 'number' } /** 특별계좌개인환불 */,
    { fieldName: 'spcl_act_corp_rprf', dataType: 'number' } /**특별계좌법인환불 */,
    { fieldName: 'spcl_act_img_accl', dataType: 'number' } /**특별계좌이미지정산 */,
    { fieldName: 'spcl_act_onl_accl', dataType: 'number' } /** 특별계좌온라인정산  */,
    { fieldName: 'etc_rcv_am', dataType: 'number' } /**기타입금 */,
    // { fieldName: 'rcv_ttam', dataType: 'number' } /** 입금총액 */,
    { fieldName: 'psn_rprf', dataType: 'number' } /** 개인환불 */,
    { fieldName: 'cad_apv_can_trn_am', dataType: 'number' } /**카드구매취소 */,
    { fieldName: 'corp_rprf', dataType: 'number' } /** 법인환불*/,
    { fieldName: 'mrch_fee', dataType: 'number' } /**가맹점수수료 */,
    { fieldName: 'rscd_accl', dataType: 'number' } /** QR정산 */,
    { fieldName: 'col_accl', dataType: 'number' } /** CPM정산 */,
    { fieldName: 'onl_accl', dataType: 'number' } /**온라인정산 */,
    { fieldName: 'spcl_act_psn_pur', dataType: 'number' } /**특별계좌개인구매  */,
    { fieldName: 'spcl_act_corp_pur', dataType: 'number' } /** 특별계좌법인구매*/,
    { fieldName: 'spcl_act_img_col', dataType: 'number' } /** 특별계좌QR추심 */,
    { fieldName: 'ty_rtn_apv_am', dataType: 'number' } /** 낙전 */,
    { fieldName: 'etc_expd_am', dataType: 'number' } /** 기타출금 */,
    { fieldName: 'rcv_ttam', dataType: 'number' } /**입금총액  */,
    { fieldName: 'trn_am', dataType: 'number' } /**실입금액  */,
    { fieldName: 'pay_ttam', dataType: 'number' } /**출금총액  */,
    { fieldName: 'pay_amt', dataType: 'number' } /**실출금액  */,
    { fieldName: 'bal_amt', dataType: 'number' } /**계좌잔액  */,
  ],
  columns: [
    {
      fieldName: 'ist_cd',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '기관명',
    },
    {
      fieldName: 'act_no',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '계좌번호',
      textFormat: '([0-9]{4})([0-9]{3})([0-9]{6});$1-$2-$3',
      footer: { text: '합계' },
    },
    {
      fieldName: 'rmk_txt',
      type: 'data',
      width: '250',
      fillWidth: 0,
      header: '상품권명',
    },
    {
      fieldName: 'psn_act_pur',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: { text: '개인계좌\n구매', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'psn_cad_pur',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: { text: '개인카드\n구매', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'corp_pur',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: { text: '법인구매', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'col_am',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: { text: 'QR추심', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'plcy_issu',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: { text: '정책발행', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'dc_plc_prn',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: { text: '할인지원금', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'spcl_act_psn_rprf',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '특별계좌\n개인환불 ',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'spcl_act_corp_rprf',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '특별계좌\n법인환불',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'spcl_act_img_accl',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '특별계좌\n이미지정산',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'spcl_act_onl_accl',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '특별계좌\n온라인정산',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'etc_rcv_am',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '기타입금',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'rcv_ttam',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '입금총액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'psn_rprf',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '개인환불',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'cad_apv_can_trn_am',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '카드구매\n취소',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'corp_rprf',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '법인환불',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'mrch_fee',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '가맹점\n수수료',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'rscd_accl',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: 'QR정산',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'col_accl',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: 'CPM정산',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'onl_accl',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '온라인정산',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'spcl_act_psn_pur',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '특별계좌\n개인구매',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'spcl_act_corp_pur',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '특별계좌\n법인구매',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'spcl_act_img_col',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '특별계좌\nQR추심',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'ty_rtn_apv_am',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '낙전',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'etc_expd_am',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '기타출금',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'rcv_ttam',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '입금총액 ',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'trn_am',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '실입금액 ',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'pay_ttam',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '출금총액 ',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'pay_amt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '실출금액 ',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'bal_amt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '계좌잔액 ',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
  ],
};

// 기타 서울페이플러스 > 자금집계현황 - 테이블
export const etc0406v01InitTableColumns = {
  fields: [
    { fieldName: 'act_no' },
    { fieldName: 'ist_cd' },
    { fieldName: 'atn' },
    { fieldName: 'rmk_txt' },
    { fieldName: 'psn_gfa_pha_at', dataType: 'number' },
    { fieldName: 'psn_gfa_pha_cla', dataType: 'number' },
    { fieldName: 'crp_gfa_pha_at', dataType: 'number' },
    { fieldName: 'crp_gfa_pha_cla', dataType: 'number' },
    { fieldName: 'rwrd_gfa_acm_at', dataType: 'number' },
    { fieldName: 'rwrd_gfa_acm_cla', dataType: 'number' },
    { fieldName: 'psn_fd_at', dataType: 'number' },
    { fieldName: 'saa', dataType: 'number' },
    { fieldName: 'sls_cla', dataType: 'number' },
    { fieldName: 'tc_rca', dataType: 'number' },
    { fieldName: 'rcp_to_at', dataType: 'number' },
    { fieldName: 'aow_to_at', dataType: 'number' },
    { fieldName: 'pay_amt' },
    { fieldName: 'trn_am' },
    { fieldName: 'bal_amt', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'ist_cd',
      type: 'data',
      width: '90',
      fillWidth: 0,
      header: '기관명',
    },
    {
      fieldName: 'act_no',
      type: 'data',
      width: '140',
      fillWidth: 0,
      header: '계좌번호',
      textFormat: '([0-9]{4})([0-9]{3})([0-9]{6});$1-$2-$3',
      footer: { text: '합계' },
    },
    {
      fieldName: 'rmk_txt',
      type: 'data',
      width: '250',
      fillWidth: 0,
      header: '상품권명',
    },
    {
      fieldName: 'psn_gfa_pha_at',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: { text: '개인상품권\n구매금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'psn_gfa_pha_cla',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '개인상품권\n구매취소금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'crp_gfa_pha_at',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '법인상품권\n구매금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'crp_gfa_pha_cla',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: { text: '법인상품권\n구매취소금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'rwrd_gfa_acm_at',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: { text: '리워드상품권\n구매금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'rwrd_gfa_acm_cla',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: { text: '리워드상품권\n구매취소금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'psn_fd_at',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '개인환불금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'saa',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '매출금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'sls_cla',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '매출취소금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tc_rca',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '이관입금금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'rcp_to_at',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '입금총금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'aow_to_at',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '출금총금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'bal_amt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '계좌잔액',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
  ],
};

// 기타 손해배상금 > Data Correction - 테이블
export const etc0501m01InitTableColumns = {
  fields: [
    { fieldName: 'kyowhandate', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'seipindate', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'ocryn' },
    { fieldName: 'jingsugigwan' },
    { fieldName: 'sunapgigwan' },
    { fieldName: 'sunapgigwanname' },
    { fieldName: 'sunapjijeom' },
    { fieldName: 'shortname' },
    { fieldName: 'jiyeonbaesangcount', dataType: 'number' },
    { fieldName: 'jiyeonbaesangtotal', dataType: 'number' },
    { fieldName: 'insertdate' },
    { fieldName: 'inserttime' },
    { fieldName: 'updatedate' },
    { fieldName: 'updatetime' },

    { fieldName: 'kyowhandate_old' },
    { fieldName: 'seipindate_old' },
    { fieldName: 'ocryn_old' },
    { fieldName: 'jingsugigwan_old' },
    { fieldName: 'sunapgigwan_old' },
    { fieldName: 'sunapjijeom_old' },
  ],
  columns: [
    {
      fieldName: 'kyowhandate',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '교환회부일자',
      datetimeFormat: 'yyyy.MM.dd',
      footer: { text: '소계' },
    },
    {
      fieldName: 'seipindate',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '세입입금일자',
      datetimeFormat: 'yyyy.MM.dd',
      footer: { text: '' },
    },
    {
      fieldName: 'jingsugigwan',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '구청코드',
      footer: { text: '' },
    },
    {
      fieldName: 'sunapgigwan',
      type: 'data',
      width: '70',
      fillWidth: 0,
      footer: { text: '' },
    },
    {
      fieldName: 'sunapgigwanname',
      type: 'data',
      width: '120',
      fillWidth: 0,
      footer: { text: '' },
    },

    {
      fieldName: 'sunapjijeom',
      type: 'data',
      width: '70',
      fillWidth: 0,
      footer: { text: '' },
    },
    {
      fieldName: 'shortname',
      type: 'data',
      width: '100',
      fillWidth: 0,
      footer: { text: '' },
    },
    {
      fieldName: 'jiyeonbaesangcount',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '손해배상건수',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'jiyeonbaesangtotal',
      type: 'data',
      width: '160',
      fillWidth: 0,
      header: '손해배상금',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
  ],
  layout: [
    {
      name: 'companyGroup1',
      header: {
        visible: false,
      },
      direction: 'horizontal',
      items: [{ column: 'kyowhandate', footerUserSpans: [{ colspan: 7 }] }],
    },
    'seipindate',
    'jingsugigwan',
    {
      name: 'hd_gr01',
      direction: 'horizontal',
      hideChildHeaders: true,
      items: [{ column: 'sunapgigwan' }, { column: 'sunapgigwanname' }],
      header: {
        text: '수납기관',
      },
    },
    {
      name: 'hd_gr01',
      direction: 'horizontal',
      hideChildHeaders: true,
      items: [{ column: 'sunapjijeom' }, { column: 'shortname' }],
      header: {
        text: '영업점',
      },
    },
    'jiyeonbaesangcount',
    'jiyeonbaesangtotal',
  ],
};

// 기타 손해배상금 > 집계표 (당행지점별) - 테이블
export const etc0501v01_1_InitTableColumns = {
  fields: [
    { fieldName: 'is_sum' },
    { fieldName: 'order1' },
    { fieldName: 'sunapgigwan' },
    { fieldName: 'sunapgigwanname' },
    { fieldName: 'gr_total', dataType: 'number' },
    { fieldName: 'tot_110', dataType: 'number' },
    { fieldName: 'tot_140', dataType: 'number' },
    { fieldName: 'tot_170', dataType: 'number' },
    { fieldName: 'tot_200', dataType: 'number' },
    { fieldName: 'tot_215', dataType: 'number' },
    { fieldName: 'tot_230', dataType: 'number' },
    { fieldName: 'tot_260', dataType: 'number' },
    { fieldName: 'tot_290', dataType: 'number' },
    { fieldName: 'tot_305', dataType: 'number' },
    { fieldName: 'tot_320', dataType: 'number' },
    { fieldName: 'tot_350', dataType: 'number' },
    { fieldName: 'tot_380', dataType: 'number' },
    { fieldName: 'tot_410', dataType: 'number' },
    { fieldName: 'tot_440', dataType: 'number' },
    { fieldName: 'tot_470', dataType: 'number' },
    { fieldName: 'tot_500', dataType: 'number' },
    { fieldName: 'tot_530', dataType: 'number' },
    { fieldName: 'tot_545', dataType: 'number' },
    { fieldName: 'tot_560', dataType: 'number' },
    { fieldName: 'tot_590', dataType: 'number' },
    { fieldName: 'tot_620', dataType: 'number' },
    { fieldName: 'tot_650', dataType: 'number' },
    { fieldName: 'tot_680', dataType: 'number' },
    { fieldName: 'tot_710', dataType: 'number' },
    { fieldName: 'tot_740', dataType: 'number' },
    { fieldName: 'tot_gita', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'sunapgigwanname',
      width: '130',
      fillWidth: 0,
      header: '구분',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          if (cell.item._dataRow === 0) {
            str = `<b style='text-decoration-line: underline;
            color: #004a80' >${cell.value}</b>`;
          } else {
            str = `${cell.value}`;
          }
          return str;
        },
      },
      footer: { text: '구청계' },
    },
    {
      fieldName: 'gr_total',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: '계',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_110',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '종로',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_140',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '중',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_170',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '용산',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_200',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '성동',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_215',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '광진',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_230',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '동대문',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_260',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '중랑',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_290',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '성북',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_305',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '강북',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_320',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '도봉',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_350',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '노원',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_380',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '은평',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_410',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '서대문',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_440',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '마포',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_470',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '양천',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_500',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '강서',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_530',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '구로',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_545',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '금천',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_560',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '영등포',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_590',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '동작',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_620',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '관악',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_650',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '서초',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_680',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '강남',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_710',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '송파',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_740',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '강동',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
    {
      fieldName: 'tot_gita',
      type: 'data',
      width: '70',
      fillWidth: 0,
      header: '기타',
      styleName: 'text-right',
      numberFormat: '#,##0',
      renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
          var str = '';
          var val = dataFormat.number(`${cell.value}`);
          if (cell.item._dataRow === 0) {
            str = "<b style='text-decoration-line: underline; color: #004a80' >" + val + '</b>';
          } else {
            str = val;
          }
          return str;
        },
      },
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
  ],
};

// 기타 손해배상금 > 집계표(당행지점별) 240312
export const etc0501v01_InitTableColumns = {
  fields: [
    { fieldName: 'is_sum' },
    { fieldName: 'sunapjijeom' },
    { fieldName: 'shortname' },
    { fieldName: 'ocryn' },
    { fieldName: 'kyowhandate', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'jiyeonbaesangtotal', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'sunapjijeom',
      type: 'data',
      width: '70',
      fillWidth: 0,

      footer: { text: '합계' },
    },
    {
      fieldName: 'shortname',
      type: 'data',
      width: '110',
      fillWidth: 0,
      footer: { text: '' },
    },
    {
      fieldName: 'ocryn',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: 'OCR/비OCR',
      footer: { text: '' },
    },
    {
      fieldName: 'kyowhandate',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '세입일자',
      datetimeFormat: 'yyyy.MM.dd',
      footer: { text: '' },
    },
    {
      fieldName: 'jiyeonbaesangtotal',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '손해배상금',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
  ],
  layout: [
    {
      name: 'hd_gr01',
      direction: 'horizontal',
      hideChildHeaders: true,
      items: [{ column: 'sunapjijeom', footerUserSpans: [{ colspan: 5 }] }, { column: 'shortname' }],
      header: {
        text: '영업점',
      },
    },
    'ocryn',
    'kyowhandate',
    'jiyeonbaesangtotal',
  ],
};

// 기타 손해배상금 > 집계표 (수납기관) - 테이블
export const etc0501v01_2_InitTableColumns = {
  fields: [
    { fieldName: 'is_sum' },
    { fieldName: 'sunapgigwan' },
    { fieldName: 'sunapgigwanname' },
    { fieldName: 'ocryn' },
    { fieldName: 'kyowhandate', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'seipindate', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'jiyeonbaesangtotal', dataType: 'number' },
    { fieldName: 'hanamt' },
    { fieldName: 'receiptpart' },
  ],
  columns: [
    {
      fieldName: 'sunapgigwan',
      type: 'data',
      width: '70',
      fillWidth: 0,

      footer: { text: '합계' },
    },
    {
      fieldName: 'sunapgigwanname',
      type: 'data',
      width: '110',
      fillWidth: 0,
      footer: { text: '' },
    },
    {
      fieldName: 'ocryn',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: 'OCR/비OCR',
      footer: { text: '' },
    },
    {
      fieldName: 'kyowhandate',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '교환회부일자',
      datetimeFormat: 'yyyy.MM.dd',
      footer: { text: '' },
    },
    {
      fieldName: 'seipindate',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '세입입금일자',
      datetimeFormat: 'yyyy.MM.dd',
      footer: { text: '' },
    },
    {
      fieldName: 'jiyeonbaesangtotal',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '손해배상금',
      styleName: 'text-right',
      numberFormat: '#,##0',
      footer: {
        numberFormat: '#,##0',
        expression: 'sum',
        styleName: 'text-right',
      },
    },
  ],
  layout: [
    {
      name: 'hd_gr01',
      direction: 'horizontal',
      hideChildHeaders: true,
      items: [{ column: 'sunapgigwan', footerUserSpans: [{ colspan: 5 }] }, { column: 'sunapgigwanname' }],
      header: {
        text: '수납기관',
      },
    },
    'ocryn',
    'kyowhandate',
    'seipindate',
    'jiyeonbaesangtotal',
  ],
};

// 기타 손해배상금 > 집계표 (징수기관 - 상세) - 테이블
export const etc0501v01_3_InitTableColumns = {
  fields: [
    { fieldName: 'gid' },
    { fieldName: 'jingsugigwan' },
    { fieldName: 'jingsugigwanname' },
    { fieldName: 'sunapgigwan' },
    { fieldName: 'sunapgigwanname' },
    { fieldName: 'ocryn' },
    { fieldName: 'kyowhandate' },
    { fieldName: 'seipdate' },
    { fieldName: 'jiyeonbaesangtotal', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'jingsugigwan',
      type: 'data',
      width: '70',
      fillWidth: 0,
      mergeRule: {
        criteria: 'value',
      },
    },
    {
      fieldName: 'jingsugigwanname',
      type: 'data',
      width: '110',
      fillWidth: 0,
      mergeRule: {
        criteria: 'value',
      },
    },
    {
      fieldName: 'sunapgigwan',
      type: 'data',
      width: '70',
      fillWidth: 0,
      styleCallback: function (grid, dataCell) {
        var ret = {};
        if (dataCell.value === '[ 소계 ]') {
          ret.styleName = 'yellow-row';
          return ret;
        }
        if (dataCell.value === '[ 총계 ]') {
          ret.styleName = 'blue-row';
          return ret;
        }
      },
    },
    {
      fieldName: 'sunapgigwanname',
      type: 'data',
      width: '110',
      fillWidth: 0,
    },
    {
      fieldName: 'ocryn',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: 'OCR/비OCR',
    },
    {
      fieldName: 'kyowhandate',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '교환회부일자',
    },
    {
      fieldName: 'seipdate',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '세입입금일자',
    },
    {
      fieldName: 'jiyeonbaesangtotal',
      type: 'data',
      width: '110',
      fillWidth: 0,
      header: '손해배상금',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
  ],
  layout: [
    {
      name: 'hd_gr01',
      direction: 'horizontal',
      hideChildHeaders: true,
      items: [{ column: 'jingsugigwan' }, { column: 'jingsugigwanname' }],
      header: {
        text: '징수기관',
      },
    },
    {
      name: 'hd_gr02',
      direction: 'horizontal',
      hideChildHeaders: true,
      items: [{ column: 'sunapgigwan' }, { column: 'sunapgigwanname' }],
      header: {
        text: '수납기관',
      },
    },
    'ocryn',
    'kyowhandate',
    'seipdate',
    'jiyeonbaesangtotal',
  ],
};

// 제로페이 결제현황조회 - 구분 별 차트옵션

/** 요일별 */
const dayChartOptions = {
  chart: {
    type: 'column',
    height: 312, // 레전드 포함 차트 전체 영역 높이
    marginBottom: 74, // 값에 따라 차트 높이 조절됨 (height - marginBottom - 10)
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      categories: ['월', '화', '수', '목', '금', '토', '일'],
      crosshair: true,
    },
  ],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return Highcharts.numberFormat(this.value, 0, '', ','); // Add comma as the thousands separator
      },
    },
  },
  tooltip: {
    pointFormatter: function () {
      const { valueSuffix } = this.series.tooltipOptions;
      // Add comma as the thousands separator
      let tooltipText = `<b>${Highcharts.numberFormat(this.y, 0, ',', ',')}</b>${valueSuffix}<br/>`;
      if (this.series.type === 'column') {
        tooltipText += `<b>${Highcharts.numberFormat(this.additionalData, 0, ',', ',')}</b>${this.additionalSuffix}`;
      } else {
        tooltipText += `${
          this.biz_nm && this.biz_nm.trim()
            ? `<b>${this.biz_nm} | ${this.max_apv_dept_nm} | ${this.max_apv_user_id}</b>`
            : ''
        }`;
      }

      return tooltipText;
    },
    shared: false,
    headerFormat: '',
    // style
    borderRadius: 8,
    padding: 12,
    style: {
      fontSize: '14px',
      lineHeight: '20px', // Adjust the line spacing here
    },
  },
  colors: ['#99C1FF', '#263752'],
  series: [
    {
      dataLabels: '',
      name: '기간내 총 결제금액',
      type: 'column',
      data: [],
      tooltip: {
        valueSuffix: '원',
      },
    },
    {
      dataLabels: '',
      name: '일 최고결제금액',
      type: 'spline',
      data: [],
      tooltip: {
        valueSuffix: '건',
      },
      lineWidth: 1,
      marker: {
        radius: 5, // point size
      },
    },
  ],
  legend: {
    layout: 'horizontal',
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 16,
    floating: true,
    itemDistance: 12, // legend 간 간격
    symbolPadding: 6,
    symbolWidth: 14, // line타입 심볼의 사이즈
    symbolHeight: 12, // 심볼 사이즈
  },
};

/** 시간별 */
const hourChartOptions = {
  chart: {
    type: 'column',
    height: 312, // 레전드 포함 차트 전체 영역 높이
    marginBottom: 74, // 값에 따라 차트 높이 조절됨 (height - marginBottom - 10)
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      categories: [],
      crosshair: true,
    },
  ],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return Highcharts.numberFormat(this.value, 0, '', ','); // Add comma as the thousands separator
      },
    },
    // min: 0,
    // max: 4000000000,
  },
  tooltip: {
    headerFormat: `<span>{point.key}</span><br />`, // xAxis 타이틀
    pointFormatter: function () {
      // const categoryName = this.series.xAxis.categories[this.index];
      return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${Highcharts.numberFormat(
        this.y,
        0,
        ',',
        ','
      )}</b><br/>`; // Add comma as the thousands separator
    },
    shared: true,
    // style
    borderRadius: 8,
    padding: 12,
    style: {
      fontSize: '14px',
      lineHeight: '20px', // Adjust the line spacing here
    },
  },
  colors: ['#99C1FF', '#263752'],
  series: [
    {
      dataLabels: '',
      name: '시간내 총 결제금액',
      type: 'column',
      data: [],
      tooltip: {
        valueSuffix: '원',
      },
    },
    {
      dataLabels: '',
      name: '누적 결제건수',
      type: 'spline',
      data: [],
      tooltip: {
        valueSuffix: '건',
      },
      lineWidth: 1,
      marker: {
        radius: 5, // point size
      },
    },
  ],
  legend: {
    layout: 'horizontal',
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 16,
    floating: true,
    itemDistance: 12, // legend 간 간격
    symbolPadding: 6,
    symbolWidth: 14, // line타입 심볼의 사이즈
    symbolHeight: 12, // 심볼 사이즈
  },
};

/** 금액별 */
const amountChartOptions = {
  chart: {
    type: 'column',
    height: 312, // 레전드 포함 차트 전체 영역 높이
    marginBottom: 74, // 값에 따라 차트 높이 조절됨 (height - marginBottom - 10)
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      categories: [],
      crosshair: true,
    },
  ],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return Highcharts.numberFormat(this.value, 0, '', ','); // Add comma as the thousands separator
      },
    },
    // min: 0,
    // max: 10000000000,
  },
  tooltip: {
    headerFormat: `<span>{point.key}</span><br />`, // xAxis 타이틀
    pointFormatter: function () {
      // const categoryName = this.series.xAxis.categories[this.index];
      return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${Highcharts.numberFormat(
        this.y,
        0,
        ',',
        ','
      )}</b><br/>`; // Add comma as the thousands separator
    },
    shared: true,
    // style
    borderRadius: 8,
    padding: 12,
    style: {
      fontSize: '14px',
      lineHeight: '20px', // Adjust the line spacing here
    },
  },
  colors: ['#99C1FF', '#3284FF'],
  series: [
    {
      dataLabels: '',
      name: '구간별 총 결제금액',
      type: 'column',
      data: [],
      tooltip: {
        valueSuffix: '원',
      },
    },
    {
      dataLabels: '',
      name: '구간별 총 결제건수',
      type: 'column',
      data: [],
      tooltip: {
        valueSuffix: '건',
      },
    },
  ],
  legend: {
    layout: 'horizontal',
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 16,
    floating: true,
    itemDistance: 12, // legend 간 간격
    symbolPadding: 6,
    symbolWidth: 14, // line타입 심볼의 사이즈
    symbolHeight: 12, // 심볼 사이즈
  },
};

/** 업종별 */
const industryChartOptions = {
  chart: {
    type: 'column',
    height: 312, // 레전드 포함 차트 전체 영역 높이
    marginBottom: 74, // 값에 따라 차트 높이 조절됨 (height - marginBottom - 10)
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      categories: [],
      crosshair: true,
    },
  ],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return Highcharts.numberFormat(this.value, 0, '', ','); // Add comma as the thousands separator
      },
    },
    // min: 0,
    // max: 10000000000,
  },
  tooltip: {
    headerFormat: `<span>{point.key}</span><br />`, // xAxis 타이틀
    pointFormatter: function () {
      // const categoryName = this.series.xAxis.categories[this.index];
      return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${Highcharts.numberFormat(
        this.y,
        0,
        ',',
        ','
      )}</b><br/>`; // Add comma as the thousands separator
    },
    shared: true,
    // style
    borderRadius: 8,
    padding: 12,
    style: {
      fontSize: '14px',
      lineHeight: '20px', // Adjust the line spacing here
    },
  },
  colors: ['#99C1FF', '#3284FF'],
  series: [
    {
      dataLabels: '',
      name: '기간내 총 결제금액',
      type: 'column',
      data: [
        4000000000, 6500000000, 2500000000, 8500000000, 6900000000, 2300000000, 7000000000, 9000000000, 6900000000,
        2000000000, 4600000000, 7700000000,
      ],
      tooltip: {
        valueSuffix: '원',
      },
    },
    {
      dataLabels: '',
      name: '기간내 총 결제건수',
      type: 'column',
      data: [
        1000000000, 1900000000, 1300000000, 2400000000, 1100000000, 1600000000, 1100000000, 1200000000, 1600000000,
        1300000000, 1600000000, 1000000000,
      ],
      tooltip: {
        valueSuffix: '건',
      },
    },
  ],
  legend: {
    layout: 'horizontal',
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 16,
    floating: true,
    itemDistance: 12, // legend 간 간격
    symbolPadding: 6,
    symbolWidth: 14, // line타입 심볼의 사이즈
    symbolHeight: 12, // 심볼 사이즈
  },
};

/** 기관/부서별 */

const departmentChartOptions = {
  chart: {
    type: 'column',
    height: 442, // 레전드 포함 차트 전체 영역 높이
    marginBottom: 198, // 값에 따라 차트 높이 조절됨 (height - marginBottom - 10)
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      categories: [],
      crosshair: true,
    },
  ],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return Highcharts.numberFormat(this.value, 0, '', ','); // Add comma as the thousands separator
      },
    },
    // min: 0,
    // max: 25000000,
  },
  tooltip: {
    headerFormat: `<span>{point.key}</span><br />`, // xAxis 타이틀
    pointFormatter: function () {
      // const categoryName = this.series.xAxis.categories[this.index];
      return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${Highcharts.numberFormat(
        this.y,
        0,
        ',',
        ','
      )}</b><br/>`; // Add comma as the thousands separator
    },
    shared: true,
    // style
    borderRadius: 8,
    padding: 12,
    style: {
      fontSize: '14px',
      lineHeight: '20px', // Adjust the line spacing here
    },
  },
  colors: ['#99C1FF', '#3284FF'],
  series: [
    {
      dataLabels: '',
      name: '기간내 총 결제금액',
      type: 'column',
      data: [],
      tooltip: {
        valueSuffix: '원',
      },
    },
    {
      dataLabels: '',
      name: '기간내 총집행금액',
      type: 'column',
      data: [],
      tooltip: {
        valueSuffix: '건',
      },
    },
  ],
  legend: {
    layout: 'horizontal',
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 0,
    floating: true,
    itemDistance: 12, // legend 간 간격
    symbolPadding: 6,
    symbolWidth: 14, // line타입 심볼의 사이즈
    symbolHeight: 12, // 심볼 사이즈
  },
};

/** 사용 지역별 */
const regionChartOptions = {
  chart: {
    type: 'column',
    height: 312, // 레전드 포함 차트 전체 영역 높이
    marginBottom: 74, // 값에 따라 차트 높이 조절됨 (height - marginBottom - 10)
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      categories: [],
      crosshair: true,
    },
  ],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function () {
        return Highcharts.numberFormat(this.value, 0, '', ','); // Add comma as the thousands separator
      },
    },
    // min: 0,
    // max: 30000000000,
  },
  tooltip: {
    headerFormat: `<span>{point.key}</span><br />`, // xAxis 타이틀
    pointFormatter: function () {
      // const categoryName = this.series.xAxis.categories[this.index];
      return `<span style="color:${this.color}">\u25CF</span> <b>${Highcharts.numberFormat(
        this.y,
        0,
        ',',
        ','
      )}</b><br/>`; // Add comma as the thousands separator
    },
    shared: true,
    // style
    borderRadius: 8,
    padding: 12,
    style: {
      fontSize: '14px',
      lineHeight: '20px', // Adjust the line spacing here
    },
  },
  colors: ['#99C1FF'],
  series: [
    {
      dataLabels: {
        enabled: true,
        formatter: function () {
          return Highcharts.numberFormat(this.y, 0, ',', ',');
        },
      },
      data: [],
      tooltip: {
        valueSuffix: '원',
      },
    },
  ],
  legend: {
    layout: 'horizontal',
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 16,
    floating: true,
    itemDistance: 12, // legend 간 간격
    symbolPadding: 6,
    symbolWidth: 14, // line타입 심볼의 사이즈
    symbolHeight: 12, // 심볼 사이즈
  },
};

export const ZPB0106V01_Category_ChartOptions = {
  day: dayChartOptions,
  hour: hourChartOptions,
  amount: amountChartOptions,
  industry: industryChartOptions,
  department: departmentChartOptions,
  region: regionChartOptions,
};

// 제로페이 결제현황조회 - 구분 별 디테일 데이터
export const zpbStlPtsInitSmorData = [
  {
    id: 1,
    title: '기간내 총 결제금액',
    value: 0,
    unit: '원',
    isHigh: false,
  },
  {
    id: 2,
    title: '월 최고 결제금액',
    value: 0,
    unit: '원',
    isHigh: true,
  },
];

export const periodDetailOptions = [
  {
    id: 1,
    title: '기간내 총 결제금액',
    value: '26,701,177,565',
    unit: '원',
    isHigh: false,
  },
  {
    id: 2,
    title: '기간내 총 결제금액',
    value: '10,000,000',
    unit: '원',
    isHigh: true,
  },
];
export const dayDetailOptions = [
  {
    id: 1,
    title: '기간내 총 결제금액',
    value: '26,701,177,565',
    unit: '원',
    isHigh: false,
  },
  {
    id: 2,
    title: '일 최고결제금액',
    value: '10,000,000',
    unit: '원',
    isHigh: true,
  },
];
export const hourDetailOptions = [
  {
    id: 1,
    title: '기간내 총 결제금액',
    value: '26,701,177,565',
    unit: '원',
    isHigh: false,
  },
  {
    id: 2,
    title: '시간내 최고결제금액',
    value: '26,701,177,565',
    unit: '원',
    isHigh: true,
  },
  {
    id: 3,
    title: '기간내 누적결제건수',
    value: '20,000',
    unit: '건',
    isHigh: false,
  },
];
export const amountDetailOptions = [
  {
    id: 1,
    title: '기간내 총 결제건수',
    value: '177,565',
    unit: '건',
    isHigh: false,
  },
  {
    id: 2,
    title: '기간내 최다결제 구간',
    value: '5',
    unit: '만원 미만',
    isHigh: true,
  },
];
export const industryDetailOptions = [
  {
    id: 1,
    title: '기간내 총 결제건수',
    value: '255,932',
    unit: '건',
    isHigh: false,
  },
  {
    id: 2,
    title: '기간내 총결제건수(상위10개)',
    value: '151,740',
    unit: '건',
    isHigh: true,
  },
  {
    id: 3,
    title: '기간내 최고 결제 업종명',
    value: '한식',
    unit: '',
    isHigh: true,
  },
];
export const departmentDetailOptions = [
  {
    id: 1,
    title: '기간내 총 결제금액',
    value: '2,091,801,705',
    unit: '원',
    isHigh: false,
  },
  {
    id: 2,
    title: '기간내 총집행금액',
    value: '457,691,330',
    unit: '원',
    isHigh: true,
  },
];
export const regionDetailOptions = [
  {
    id: 1,
    title: '기간내 총 결제금액',
    value: '26,701,177,565',
    unit: '원',
    isHigh: false,
  },
  {
    id: 2,
    title: '기간내 최고결제 행정구역',
    value: '서울',
    unit: '',
    isHigh: true,
  },
];

export const ZPB0106V01_Category_DetailOptions = {
  period: periodDetailOptions,
  day: dayDetailOptions,
  hour: hourDetailOptions,
  amount: amountDetailOptions,
  industry: industryDetailOptions,
  department: departmentDetailOptions,
  region: regionDetailOptions,
};

// 제로페이 결제현황조회 - 테이블
export const zpbStlPtsInitTableColumns = {
  fields: [
    { fieldName: 'apv_dt' },
    { fieldName: 'apv_sum_am', dataType: 'number' },
    { fieldName: 'apv_tot_cnt' },
    { fieldName: 'apv_max_am' },
    { fieldName: 'max_apv_user_id' },
    { fieldName: 'biz_nm' },
    { fieldName: 'max_apv_dept_nm' },
    { fieldName: 'local' },
    { fieldName: 'dept_nm' },
    { fieldName: 'exu_sum_am', dataType: 'number' },
    { fieldName: 'dis_nm' },
    { fieldName: 'cnt', dataType: 'number' },
    { fieldName: 'bzct_nm' },
    { fieldName: 'use_cnt', dataType: 'number' },
    { fieldName: 'apv_cnt', dataType: 'number' },
    { fieldName: 'exu_sum_cnt', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'dis_nm',
      type: 'data',
      width: '150',
      fillWidth: 0,
      header: '구분',
    },
    {
      fieldName: 'cnt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '권한부여',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'use_cnt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '실(접속)가입자',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'apv_cnt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '승인건수',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'apv_sum_am',
      type: 'data',
      width: '150',
      fillWidth: 0,
      header: '승인금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'exu_sum_cnt',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '정산건수',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'exu_sum_am',
      type: 'data',
      width: '150',
      fillWidth: 0,
      header: '정산금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
  ],
  layout: [
    'dis_nm',
    {
      name: 'columnGroup1',
      direction: 'horizontal',
      items: ['cnt', 'use_cnt'],
      header: {
        text: '사용자수',
      },
    },
    {
      name: 'columnGroup2',
      direction: 'horizontal',
      items: ['apv_cnt', 'apv_sum_am', 'exu_sum_cnt', 'exu_sum_am'],
      header: {
        text: '거래현황 ',
      },
    },
  ],
};

// 제로페이 결제현황조회 - 기관/부서별 결제현황 리스트 테이블 (modal 테이블)
export const zpbStlPtsInitTableColumnsByIstDept = {
  fields: [
    { fieldName: 'no' },
    { fieldName: 'upche_gbn_cd' },
    { fieldName: 'bznm' },
    { fieldName: 'dept_nm' },
    { fieldName: 'wdr_acno' },
    { fieldName: 'bzno' },
    { fieldName: 'apv_cnt', dataType: 'number' },
    { fieldName: 'apv_sum_am', dataType: 'number' },
    { fieldName: 'exu_cnt', dataType: 'number' },
    { fieldName: 'exu_sum_am', dataType: 'number' },
  ],
  columns: [
    {
      fieldName: 'upche_gbn_cd',
      type: 'data',
      width: '140',
      fillWidth: 1,
      header: '사용자구분',
    },
    {
      fieldName: 'bznm',
      type: 'data',
      width: '100',
      fillWidth: 0,
      header: '기관명',
    },
    {
      fieldName: 'dept_nm',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '부서명',
    },
    {
      fieldName: 'wdr_acno',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '계좌번호',
    },
    {
      fieldName: 'bzno',
      type: 'data',
      width: '130',
      fillWidth: 0,
      header: '사업자번호',
    },
    {
      fieldName: 'apv_cnt',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: { text: '기간내\n총 결제건수', styleName: 'white-space' },
      numberFormat: '#,##0',
    },
    {
      fieldName: 'apv_sum_am',
      type: 'data',
      width: '90',
      fillWidth: 0,
      header: '기간내 총 결제금액',
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
    {
      fieldName: 'exu_cnt',
      type: 'data',
      width: '80',
      fillWidth: 0,
      header: { text: '기간내\n총 집행건수', styleName: 'white-space' },
      numberFormat: '#,##0',
    },
    {
      fieldName: 'exu_sum_am',
      type: 'data',
      width: '90',
      fillWidth: 0,
      header: { text: '기간내\n총 집행금액', styleName: 'white-space' },
      styleName: 'text-right',
      numberFormat: '#,##0',
    },
  ],
};

// 제로페이(관리자) - 사용자 이력 조회 - 테이블
export const zpb0206v01InitTableColumns = {
  fields: [
    { fieldName: 'num' },
    { fieldName: 'user_id' },
    { fieldName: 'chg_date' },
    { fieldName: 'chg_time' },
    { fieldName: 'chg_detail' },
    { fieldName: 'trn_dscd' },
    { fieldName: 'can_dt' },
    { fieldName: 'exist_yn' },
    { fieldName: 'co_cus_mst_id' },
    { fieldName: 'bef_user_nm' },
    { fieldName: 'aft_user_nm' },
    { fieldName: 'bef_dept_nm' },
    { fieldName: 'aft_dept_nm' },
    { fieldName: 'chg_lgin_id' },
    { fieldName: 'lst_chg_pw_dt' },
    { fieldName: 'lst_chg_pw_id' },
    { fieldName: 'trn_dtm' },
    { fieldName: 'chg_dt' },
    { fieldName: 'fst_rgs_dt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
    { fieldName: 'rgs_dt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
  ],
  columns: [
    {
      fieldName: 'chg_date',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '거래일자',
    },
    {
      fieldName: 'num',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '거래일련번호',
      visible: false,
    },
    {
      fieldName: 'user_id',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '사용자 ID',
    },
    {
      fieldName: 'co_cus_mst_id',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '마스터 ID',
    },
    {
      fieldName: 'chg_time',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '거래시간',
    },
    {
      fieldName: 'fst_rgs_dt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '최초등록일',
      datetimeFormat: 'yyyy.MM.dd',
      visible: false,
    },
    {
      fieldName: 'rgs_dt',
      type: 'data',
      width: '120',
      fillWidth: 0,
      header: '등록일',
      datetimeFormat: 'yyyy.MM.dd',
      visible: false,
    },
    {
      fieldName: 'chg_detail',
      type: 'data',
      width: '400',
      fillWidth: 1,
      header: '거래내용',
      styleName: 'text-left',
    },
    {
      fieldName: 'chg_dt',
      type: 'data',
      width: '120',
      fillWidth: 1,
      header: '처리일자',
      visible: false,
    },
    {
      fieldName: 'bef_user_nm',
      type: 'data',
      width: '125',
      fillWidth: 1,
      header: '사용자명',
      visible: false,
    },
    {
      fieldName: 'bef_dept_nm',
      type: 'data',
      width: '125',
      fillWidth: 1,
      header: '부서명',
      visible: false,
    },
    {
      fieldName: 'aft_user_nm',
      type: 'data',
      width: '125',
      fillWidth: 1,
      header: '사용자명',
      visible: false,
    },
    {
      fieldName: 'aft_dept_nm',
      type: 'data',
      width: '125',
      fillWidth: 1,
      header: '부서명',
      visible: false,
    },
  ],
  layout: [
    'chg_date',
    'num',
    'user_id',
    'co_cus_mst_id',
    'chg_time',
    'fst_rgs_dt',
    'rgs_dt',
    'chg_detail',
    'chg_dt',

    {
      name: 'b-group',
      direction: 'horizontal',
      items: ['bef_user_nm', 'bef_dept_nm'],
      header: {
        text: '변경 전',
      },
    },
    {
      name: 'a-group',
      direction: 'horizontal',
      items: ['aft_user_nm', 'aft_dept_nm'],
      header: {
        text: '변경 후',
      },
    },
  ],
};
