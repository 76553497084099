import { fetchCommonComboList } from '../../../lib/cmm/combo.api';
import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';

// actions
const FETCH_COMBO = (sltBoxNo) => `combo/FETCH_COMMON_${sltBoxNo}_COMBO`;

export function comboReducerList() {
  const sltBoxNoList = [
    '003',
    '005',
    '008',
    '010',
    '012',
    '013',
    '017',
    '017',
    '019',
    '023',
    '025',
    '032',
    '032',
    '034',
    '039',
    '040',
    '041',
    '042',
    '044',
    '045',
    '049',
    '053',
    '057',
    '061',
    '064',
    '065',
    '068',
    '071',
    '072',
    '074',
    '091',
    '102',
    '107',
    '115',
    '128',
    '131',
    '132',
    '133',
    '134',
    '137',
    '142',
    '143',
    '143',
    '152',
    '157',
    '158',
    '165',
    '183',
    '186',
    '191',
    '191',
    '193',
    '196',
    '198',
    '198',
    '199',
    '200',
    '201',
    '204',
    '206',
    '207',
    '209',
    '210',
    '901',
    '902',
    '925',
    '926',
    '928',
  ];

  let comboReducers = {};
  sltBoxNoList.forEach((sltBoxNo) => {
    // actions
    const FETCH_COMMON_COMBO = `combo/FETCH_COMMON_${sltBoxNo}_COMBO`;
    comboReducers[`combo${sltBoxNo}`] = (
      state = {
        options: {},
        loading: {},
        success: {},
      },
      action
    ) => {
      switch (action.type) {
        case REQUEST(FETCH_COMMON_COMBO): // 조회 요청
          return {
            ...state,
            success: { ...state.loading, [`CB${sltBoxNo}`]: false },
            loading: { ...state.loading, [`CB${sltBoxNo}`]: true },
            options: { ...state.options, [`CB${sltBoxNo}`]: [] },
          };
        case SUCCESS(FETCH_COMMON_COMBO): // 조회 성공
          return {
            ...state,
            success: { ...state.success, [`CB${sltBoxNo}`]: true },
            loading: { ...state.loading, [`CB${sltBoxNo}`]: false },
            options: { ...state.options, [`CB${sltBoxNo}`]: action.payload.data?.subDto },
          };
        case FAILURE(FETCH_COMMON_COMBO): // 조회 실패
          return {
            ...state,
            success: { ...state.success, [`CB${sltBoxNo}`]: false },
            loading: { ...state.loading, [`CB${sltBoxNo}`]: false },
            options: { ...state.options, [`CB${sltBoxNo}`]: [] },
          };
        default:
          return state;
      }
    };
  });
  return comboReducers;
}

/**
 * 공통콤보 조회
 */
export const selectCommonComboList2 = (searchParams) => async (dispatch) => {
  return dispatch({
    type: FETCH_COMBO(searchParams.slt_box_no),
    payload: fetchCommonComboList(searchParams),
  });
};
